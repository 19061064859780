import React from "react"
import { Th, Thead, Tr } from "@chakra-ui/react"
import { cfg } from "../../../cfg"

const TableHeader = () => {
  return (
    <Thead
      bg="gray.50"
      position="sticky"
      top="0"
      zIndex="sticky"
      boxShadow="0 2px 4px -1px rgba(0, 0, 0, 0.2)"
    >
      <Tr>
        {Object.entries(cfg.statTable.headColumnWidth).map(
          ([key, { width, title }]) => (
            <Th width={width} py="3" px="4" fontWeight="bold" key={key}>
              {title}
            </Th>
          ),
        )}
      </Tr>
    </Thead>
  )
}

export default TableHeader
