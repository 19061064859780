import React, { useEffect, useState } from "react"
import { Button, Flex } from "@chakra-ui/react"
import { FieldValues, useForm } from "react-hook-form"
import { AppSelectInputWithSearch } from "../../../components/inputs/AppSelectInputWithSearch"
import { useAppDispatch, useUserInfo } from "../../../app/hooks"
import CustomForm from "../../../components/CustomForm/CustomForm"
import { cfg } from "../../../cfg"
import { fetchUserAccessRightsByIdDirectly } from "../../../api/api"
import { updateSellerAccess } from "../additionalUserRightsAPI"
import { createSelectOptions, updateAccessRightList } from "../../../utils"
import {
  showUpdateErrorAlert,
  showUpdateSuccessAlert,
} from "../../../common/apiCrudAlertHelper"

interface EntryFormProps {
  isOpen: boolean
  onClose: () => void
}

const AdditionalUserRightsForm: React.FC<EntryFormProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useAppDispatch()

  const [accessRights, setAccessRights] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const {
    control,
    handleSubmit: useFormSubmit,
    formState: { errors, isSubmitting },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: cfg.userAccessForm.defaultValues,
  })

  const watchedValues = watch()

  const fetchAccessRights = async () => {
    if (watchedValues.sellerId) {
      setIsLoading(true)
      setError(null)
      try {
        const data: any = await fetchUserAccessRightsByIdDirectly(
          watchedValues.sellerId,
        )
        const accessRightList = (data?.[0]?.shop_id_list ?? []).map(
          (accessRightItem: any) => {
            return parseFloat(accessRightItem)
          },
        )
        setAccessRights(accessRightList)
        setValue(cfg.userAccessForm.fields.shopIdList.name, accessRightList)
      } catch (error) {
        setError(error instanceof Error ? error.message : String(error))
      } finally {
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchAccessRights()
  }, [watchedValues.sellerId, setValue])

  const onSubmit = async (data: any) => {
    await dispatch(updateSellerAccess(data))
      .unwrap()
      .then((originalPromiseResult) => {
        showUpdateSuccessAlert()
      })
      .catch((rejectedValueOrSerializedError) => {
        showUpdateErrorAlert(rejectedValueOrSerializedError.message)
      })
    await updateAccessRightList(dispatch)
  }

  const handleFormSubmit = async (data: FieldValues) => {
    await onSubmit(data)
    reset()
    onClose()
  }

  const userInfo = useUserInfo()

  const sellerOptions = createSelectOptions(
    userInfo.user?.availableUserList || [],
  )

  const shopOptions = createSelectOptions(
    userInfo.user?.availableShopList || [],
  )

  const footer = (
    <Button
      isLoading={isSubmitting}
      type="submit"
      colorScheme="yellow"
      width="100%"
    >
      Оновити
    </Button>
  )

  return (
    <CustomForm
      title="Налаштувати додаткові права"
      isOpen={isOpen}
      onClose={() => {
        onClose()
        reset()
      }}
      useFormSubmit={useFormSubmit}
      modalFooter={footer}
      handleSubmit={handleFormSubmit}
    >
      <Flex direction="column" gap="4">
        <AppSelectInputWithSearch
          value={watchedValues[cfg.userAccessForm.fields.sellerId.name]}
          placeholder={cfg.userAccessForm.fields.sellerId.placeholder}
          rules={{ required: cfg.useFormRules.requiredMsg }}
          control={control}
          name={cfg.userAccessForm.fields.sellerId.name}
          label={cfg.userAccessForm.fields.sellerId.label}
          options={sellerOptions}
          errors={errors}
        />
        <AppSelectInputWithSearch
          value={watchedValues[cfg.userAccessForm.fields.shopIdList.name].map(
            (shopId) => parseFloat(shopId),
          )}
          w={"100%"}
          rules={{
            required: cfg.useFormRules.requiredMsg,
            validate: cfg.useFormRules.validateShopSelection,
          }}
          placeholder={cfg.userAccessForm.fields.shopIdList.placeholder}
          control={control}
          isMulti
          name={cfg.userAccessForm.fields.shopIdList.name}
          label={cfg.userAccessForm.fields.shopIdList.label}
          options={shopOptions}
          errors={errors}
        />
      </Flex>
    </CustomForm>
  )
}

export default AdditionalUserRightsForm
