import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react"
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
} from "react-hook-form"

import "react-datepicker/dist/react-datepicker.css"

type Props<T extends Partial<FieldValues>> = {
  control: Control<T>
  name: Path<T>
  label?: string
  errors: any
  rules?: RegisterOptions
  value?: Date
}

export const AppInputDatePicker = <T extends FieldValues>({
  control,
  name,
  label,
  errors,
  rules,
  value,
}: Props<T>) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date())
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    if (value) {
      setStartDate(value)
    }
  }, [value])

  const getBorderStyle = (isError: boolean, isFocused: boolean) => {
    if (isFocused) return "1px solid #3182ce" // Синя рамка при фокусі
    return isError ? "2px solid red" : "1px solid #E2E8F0" // Червона рамка при помилці, інакше звичайна
  }

  return (
    <FormControl zIndex="2" isInvalid={!!errors[name]}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Controller
        control={control}
        name={name}
        rules={{
          ...rules,
        }}
        render={({ field: { onChange, ref, name } }) => (
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date)
              onChange(date)
            }}
            customInput={
              <input
                ref={ref}
                style={{
                  padding: "10px",
                  height: "40px",
                  border: getBorderStyle(!!errors[name], isFocused),
                  borderRadius: "7px",
                }}
                onFocus={(e) => {
                  setIsFocused(true)
                  e.target.style.border = getBorderStyle(false, true)
                }}
                onBlur={(e) => {
                  setIsFocused(false)
                  e.target.style.border = getBorderStyle(!!errors[name], false)
                }}
              />
            }
          />
        )}
      />
      {!!errors[name] && (
        <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
      )}
    </FormControl>
  )
}
