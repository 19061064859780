import React from "react"
import { Flex, IconButton, Td, Tooltip, Tr } from "@chakra-ui/react"
import { DeleteIcon, EditIcon, ViewIcon } from "@chakra-ui/icons"
import { Note } from "../../../types/note"
import { UserState } from "../../user/userSlice"
import {
  calculatePercentageFromMargin,
  calculateSalaryPerDayForTableRaw,
  isEditBtnDisabled,
} from "../../../utils"
import { cfg } from "../../../cfg"

interface CustomModalProps {
  note: Note
  onEdit: () => void
  onView: () => void
  onDelete: () => void
  userInfo: UserState
}

const TableRow: React.FC<CustomModalProps> = ({
  note,
  onEdit,
  onView,
  onDelete,
  userInfo,
}) => {
  const isAdmin = !!userInfo.user?.isAdmin
  const isEditDisabled = !isAdmin && isEditBtnDisabled(note, userInfo)

  const editTooltipLabel = isEditDisabled
    ? cfg.toolTipText.editDoNotAllow
    : cfg.toolTipText.editAllow

  const canDeleteNonAdmin = (noteDate: string) => {
    const noteDateTime = new Date(noteDate).setHours(0, 0, 0, 0)
    const today = new Date().setHours(0, 0, 0, 0)
    const yesterday = new Date(today).setDate(new Date(today).getDate() - 1)

    return noteDateTime === today || noteDateTime === yesterday
  }

  let deleteTooltipLabel: string = cfg.toolTipText.deleteDoNotAllow

  if (isAdmin || canDeleteNonAdmin(note.date)) {
    deleteTooltipLabel = cfg.toolTipText.deleteAllow
  }

  const isDeleteDisabled = isAdmin ? false : !canDeleteNonAdmin(note.date)

  return (
    <Tr
      sx={{
        "&:hover": { backgroundColor: "yellow.100" },
      }}
    >
      <Td py="2" px="3">
        {new Date(note.date).toLocaleDateString()}
      </Td>
      <Td py="2" px="3">
        {note.responsiblename}
      </Td>
      <Td py="2" px="3">
        {note.shopname}
      </Td>
      <Td py="2" px="3">
        {note.cardreceipts} {cfg.currencyPrefix}
      </Td>
      <Td py="2" px="3">
        {note.cashreceipts} {cfg.currencyPrefix}
      </Td>
      <Td py="2" px="3">
        {note.totalreceipts} {cfg.currencyPrefix}
      </Td>
      <Td py="2" px="3">
        {note.margin} {cfg.currencyPrefix}
      </Td>
      <Td py="2" px="3">
        {note.rate} {cfg.currencyPrefix}
      </Td>
      <Td py="2" px="3">
        {calculatePercentageFromMargin(note.margin, note.percentage)}
        {cfg.currencyPrefix}
      </Td>
      <Td py="2" px="3">
        {calculateSalaryPerDayForTableRaw(note)} {cfg.currencyPrefix}
      </Td>
      <Td py="2" px="3">
        <Flex>
          <Tooltip label={editTooltipLabel} placement="top">
            <IconButton
              aria-label="Edit note"
              icon={<EditIcon />}
              size="sm"
              mr="2"
              isDisabled={isEditDisabled}
              onClick={onEdit}
            />
          </Tooltip>
          <Tooltip label="Переглянути" placement="top">
            <IconButton
              aria-label="View note"
              icon={<ViewIcon />}
              size="sm"
              mr="2"
              onClick={onView}
            />
          </Tooltip>
          <Tooltip label={deleteTooltipLabel} placement="top">
            <IconButton
              aria-label="Delete note"
              icon={<DeleteIcon />}
              size="sm"
              isDisabled={isDeleteDisabled}
              onClick={onDelete}
            />
          </Tooltip>
        </Flex>
      </Td>
    </Tr>
  )
}

export default TableRow
