import React from "react"
import { useBreakpointValue } from "@chakra-ui/react"
import PCTable from "./PCTable"

interface ResponsiveComponentProps {}

const ResponsiveTableWrapper: React.FC<ResponsiveComponentProps> = () => {
  const isTable = useBreakpointValue({ base: false, md: true })

  // todo fix adaptive
  return isTable ? (
    <PCTable />
  ) : (
    <PCTable />
    // TODO here will be mobile table component
    // <BoxComponent
    //   noteList={noteList}
    //   noteStatus={noteStatus}
    //   notesError={notesError}
    // />
  )
}

export default ResponsiveTableWrapper
