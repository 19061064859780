import React, { Fragment, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Button, Center, Flex, Text } from "@chakra-ui/react"
import {
  createLocationInfo,
  deleteLocationInfo,
  fetchLocationInfoList,
  updateLocationInfo,
} from "../locationInfoAPI"
import type { RootState } from "../../../app/store"
import { LocationInfo, LocationInfoDtoIn } from "../../../types/location-info"
import {
  useAdditionalUserRights,
  useAppDispatch,
  useLocationInfo,
  useUserInfo,
} from "../../../app/hooks"
import LocationInfoItem from "./LocationInfoItem"
import { IoAccessibility } from "react-icons/io5"
import LocationInfoForm from "../forms/LocationInfoForm"
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal"
import { showToastError } from "../../../common/showAlert"
import {
  showCreateErrorAlert,
  showCreateSuccessAlert,
  showDeleteErrorAlert,
  showDeleteSuccessAlert,
} from "../../../common/apiCrudAlertHelper"
import { LoadStatus } from "../../../types/common"
import LoadingRow from "../../../components/LoadingRow/LoadingRow"

const LocationInfoList: React.FC = () => {
  const dispatch = useAppDispatch()

  const [currentLocationInfoObject, setCurrentLocationInfoObject] = useState<
    undefined | LocationInfo
  >(undefined)

  // modals
  const [isLocationInfoModalOpen, setLocationInfoModalOpen] = useState(false)
  const [isLocationInfoModalEditOpen, setLocationInfoModalEditOpen] =
    useState(false)
  const [isConfirmLocationInfoDeleteOpen, setConfirmLocationInfoDeleteOpen] =
    useState(false)

  const [deletingLocationInfoId, setDeletingLocationInfoId] = useState<
    string | null
  >(null)

  const { locationInfoConfigList, fetchStatus, fetchError } = useLocationInfo()
  const { status: userInfoStatus } = useUserInfo()

  useEffect(() => {
    dispatch(fetchLocationInfoList())
  }, [dispatch])

  // submit handlers start
  const createLocationInfoItemHandler = async (
    data: LocationInfo,
  ): Promise<void> => {
    const dtoIn: LocationInfoDtoIn = {
      rate: +data.rate,
      percentage: +data.percentage,
      type: data.type,
    }
    await dispatch(createLocationInfo(dtoIn))
      .unwrap()
      .then((originalPromiseResult) => {
        showCreateSuccessAlert()
      })
      .catch((rejectedValueOrSerializedError) => {
        showCreateErrorAlert(rejectedValueOrSerializedError.message)
      })
  }

  const editLocationInfoItemHandler = async (
    data: LocationInfo,
  ): Promise<void> => {
    if (currentLocationInfoObject?.id) {
      const locationData: LocationInfoDtoIn = {
        rate: +data.rate,
        percentage: +data.percentage,
        type: data.type,
      }
      const dtoIn = {
        id: currentLocationInfoObject?.id,
        locationData,
      }

      dispatch(updateLocationInfo(dtoIn))
    } else {
      showToastError({
        message: `LocationInfo ID not provided for edit`,
      })
    }
  }

  const handleConfirmDelete = async () => {
    if (deletingLocationInfoId !== null) {
      await dispatch(deleteLocationInfo(parseFloat(deletingLocationInfoId)))
        .unwrap()
        .then((originalPromiseResult) => {
          showDeleteSuccessAlert()
        })
        .catch((rejectedValueOrSerializedError) => {
          showDeleteErrorAlert(rejectedValueOrSerializedError.message)
        })
      handleCloseConfirm()
      setDeletingLocationInfoId(null)
    } else {
      handleCloseConfirm()
      setDeletingLocationInfoId(null)
      showToastError({
        message: `DeletingNoteId not provided`,
      })
    }
  }

  // submit handlers end

  const handleLocationInfoModalClose = () => setLocationInfoModalOpen(false)
  const handleLocationInfoModalOpen = () => setLocationInfoModalOpen(true)

  const handleLocationInfoModalEditClose = () =>
    setLocationInfoModalEditOpen(false)
  const handleLocationInfoModalEditOpen = () =>
    setLocationInfoModalEditOpen(true)

  const handleCloseConfirm = () => {
    setConfirmLocationInfoDeleteOpen(false)
  }

  const onItemEdit = (locationInfoObject: LocationInfo) => {
    handleLocationInfoModalEditOpen()
    setCurrentLocationInfoObject(locationInfoObject)
  }

  const onItemDelete = (id: string) => {
    setDeletingLocationInfoId(id)
    setConfirmLocationInfoDeleteOpen(true)
  }

  const renderLocationInfoItemList = () => {
    if (
      fetchStatus === LoadStatus.Succeeded &&
      !locationInfoConfigList.length
    ) {
      return (
        <Center my={10}>
          <Text fontSize="lg" color="gray.600">
            Немає доступних елементів для відображення.
          </Text>
        </Center>
      )
    }

    if (
      fetchStatus === LoadStatus.Succeeded &&
      userInfoStatus === LoadStatus.Succeeded
    ) {
      return locationInfoConfigList.map((item: LocationInfo) => {
        return (
          <LocationInfoItem
            key={item.id}
            item={item}
            onEdit={() => {
              onItemEdit(item)
            }}
            onDelete={() => {
              onItemDelete(item.id.toString())
            }}
          />
        )
      })
    }

    if (fetchStatus === LoadStatus.Loading || fetchStatus === LoadStatus.Idle) {
      return <LoadingRow />
    }
  }

  const confirmDeleteModal = (
    <ConfirmationModal
      isOpen={isConfirmLocationInfoDeleteOpen}
      onClose={handleCloseConfirm}
      title="Підтвердити видалення"
      onConfirm={handleConfirmDelete}
    >
      Ви впевнені, що хочете видалити цей запис?
    </ConfirmationModal>
  )

  const listHeaderSectionContent = (
    <Flex justifyContent="space-between" alignItems="center" mb={4}>
      <Text as={"b"} fontSize="2xl">
        Список типів
      </Text>
      <Button
        onClick={handleLocationInfoModalOpen}
        leftIcon={<IoAccessibility />}
        colorScheme="yellow"
        size="md"
      >
        Додати новий тип
      </Button>
    </Flex>
  )

  return (
    <Fragment>
      {listHeaderSectionContent}
      {renderLocationInfoItemList()}
      {confirmDeleteModal}
      <LocationInfoForm
        title={"Налаштування ставки та відсотку"}
        handler={createLocationInfoItemHandler}
        isOpen={isLocationInfoModalOpen}
        onClose={handleLocationInfoModalClose}
        submitTitle={"Додати"}
      />
      <LocationInfoForm
        currentObject={currentLocationInfoObject}
        title={"Редагування ставки та відсотку"}
        handler={editLocationInfoItemHandler}
        isOpen={isLocationInfoModalEditOpen}
        onClose={() => {
          handleLocationInfoModalEditClose()
          setCurrentLocationInfoObject(undefined)
        }}
        submitTitle={"Оновити"}
      />
    </Fragment>
  )
}

export default LocationInfoList
