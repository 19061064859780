import { createSelector, createSlice } from "@reduxjs/toolkit"
import { Note } from "../../types/note"
import { fetchNotes, addNote, updateNote, deleteNote } from "./noteAPI"
import { LoadStatus, PageInfo, SliceErrorType } from "../../types/common"
import { RootState } from "../../app/store"

export interface NoteState {
  notes: Note[]
  pageInfo: PageInfo | null
  fetchStatus: LoadStatus
  addStatus: LoadStatus
  updateStatus: LoadStatus
  deleteStatus: LoadStatus
  fetchError: SliceErrorType
  addError: SliceErrorType
  updateError: SliceErrorType
  deleteError: SliceErrorType
}

const initialState: NoteState = {
  notes: [],
  pageInfo: null,
  fetchStatus: LoadStatus.Idle,
  addStatus: LoadStatus.Idle,
  updateStatus: LoadStatus.Idle,
  deleteStatus: LoadStatus.Idle,
  fetchError: null,
  addError: null,
  updateError: null,
  deleteError: null,
}

const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.fetchError = null
      state.addError = null
      state.updateError = null
      state.deleteError = null
    },
    resetNoteSliceState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // Fetch notes
      .addCase(fetchNotes.pending, (state) => {
        state.fetchStatus = LoadStatus.Loading
        state.fetchError = null
      })
      .addCase(fetchNotes.fulfilled, (state, action) => {
        state.fetchStatus = LoadStatus.Succeeded
        state.notes = action.payload.noteList
        state.pageInfo = action.payload.pageInfo
      })
      .addCase(fetchNotes.rejected, (state, action) => {
        state.fetchStatus = LoadStatus.Failed
        state.fetchError = action.payload.message
      })
      // Add note
      .addCase(addNote.pending, (state) => {
        state.addStatus = LoadStatus.Loading
        state.addError = null
      })
      .addCase(addNote.fulfilled, (state, action) => {
        state.addStatus = LoadStatus.Succeeded
        state.notes.push(action.payload)
      })
      .addCase(addNote.rejected, (state, action) => {
        state.addStatus = LoadStatus.Failed
        state.addError = action.payload.message
      })
      // Update note
      .addCase(updateNote.pending, (state) => {
        state.updateStatus = LoadStatus.Loading
        state.updateError = null
      })
      .addCase(updateNote.fulfilled, (state, action) => {
        state.updateStatus = LoadStatus.Succeeded
        const index = state.notes.findIndex(
          (note) => note.id === action.payload.id,
        )
        if (index !== -1) {
          state.notes[index] = action.payload
        }
      })
      .addCase(updateNote.rejected, (state, action) => {
        state.updateStatus = LoadStatus.Failed
        state.updateError = action.payload.message
      })
      // Delete note
      .addCase(deleteNote.pending, (state) => {
        state.deleteStatus = LoadStatus.Loading
        state.deleteError = null
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.deleteStatus = LoadStatus.Succeeded
        state.notes = state.notes.filter((note) => note.id !== action.payload)
      })
      .addCase(deleteNote.rejected, (state, action) => {
        state.deleteStatus = LoadStatus.Failed
        state.deleteError = action.payload.message
      })
  },
})

export const selectNotes = (state: RootState) => state.notes

export const selectAllErrors = createSelector([selectNotes], (errors) => ({
  fetchError: errors.fetchError,
  addError: errors.addError,
  updateError: errors.updateError,
  deleteError: errors.deleteError,
}))

export const selectAllStatuses = createSelector([selectNotes], (statuses) => ({
  fetchStatus: statuses.fetchStatus,
  addStatus: statuses.addStatus,
  updateStatus: statuses.updateStatus,
  deleteStatus: statuses.deleteStatus,
}))

export const { clearErrors, resetNoteSliceState } = notesSlice.actions

export default notesSlice.reducer
