import { createAsyncThunk } from "@reduxjs/toolkit"
import axiosInstance from "../../api/axiosConfig"
import { handleAsyncThunkError } from "../../utils"
import { updateSellerAccessDto } from "../../types/access-rirhts" // Переконайтеся, що шлях правильний

export const fetchAdditionalUserRights = createAsyncThunk(
  "get-seller-access-list",
  handleAsyncThunkError(async (args: void) => {
    const response = await axiosInstance.post("get-seller-access-list")
    return response.data
  }),
)

export const updateSellerAccess = createAsyncThunk(
  "update-seller-access",
  handleAsyncThunkError(async (data: updateSellerAccessDto) => {
    const response = await axiosInstance.post("update-seller-access", data)
    return response.data
  }),
)

export const createAccessRights = createAsyncThunk(
  "create-access-rights",
  handleAsyncThunkError(async (data: updateSellerAccessDto) => {
    const response = await axiosInstance.post("create-access-rights", data)
    return response.data
  }),
)

export const deleteUserAccessRights = createAsyncThunk(
  "delete-user-access-rights",
  handleAsyncThunkError(async (userId: number) => {
    const response = await axiosInstance.delete(
      `delete-user-access-rights?userId=${userId}`,
    )
    return response.data
  }),
)
