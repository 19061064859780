import React, { useState } from "react"
import { Box, Text, Badge } from "@chakra-ui/react"
import TooltipIconButton from "../../../components/IconActionButton/IconActionButton"
import { EditIcon, DeleteIcon } from "@chakra-ui/icons"
import { Seller, UserFromAvailableUserList } from "../../../types/common"

interface UserRightsItemProps {
  user: UserFromAvailableUserList | Seller
  shop_id_list: string[]
  onEdit: () => void
  onDelete: () => void
}

const UserRightsItem: React.FC<UserRightsItemProps> = ({
  user,
  shop_id_list,
  onEdit,
  onDelete,
}) => {
  return (
    <Box
      p={4}
      shadow="md"
      borderWidth="1px"
      my={2}
      borderRadius="md"
      position="relative"
      sx={{
        "&:hover": { backgroundColor: "yellow.100" },
      }}
    >
      <Text as={"b"} fontSize="lg" mb={2}>
        Продавець:{" "}
        <Badge variant="solid" colorScheme="yellow" mr={2}>
          {user?.FullName || "Невідомий"}
        </Badge>
      </Text>
      <Text fontSize="lg" mb={2}>
        Доступ до магазинів:{" "}
        {shop_id_list.map((shop, index) => (
          <Badge key={index} variant="outline" colorScheme="yellow" mr={2}>
            {shop}
          </Badge>
        ))}
      </Text>
      <TooltipIconButton
        label="Редагувати"
        icon={<EditIcon />}
        onClick={onEdit}
        sx={{ right: "50px", top: "10px" }}
      />
      <TooltipIconButton
        label="Видалити"
        icon={<DeleteIcon />}
        onClick={onDelete}
        sx={{ right: "10px", top: "10px" }}
      />
    </Box>
  )
}

export default UserRightsItem
