import React from "react"
import { Box, Heading, Text, Button } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

const NotFound: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, yellow.400, yellow.600)"
        backgroundClip="text"
      >
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Сторінку не знайдено
      </Text>
      <Text color={"gray.500"} mb={6}>
        Сторінка, яку ви шукаєте, не існує або була переміщена в інше місце.
      </Text>

      <Button
        colorScheme="yellow"
        bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
        color="white"
        variant="solid"
        onClick={() => navigate("/")}
      >
        Повернутися на головну
      </Button>
    </Box>
  )
}

export default NotFound
