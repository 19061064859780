import React from "react"
import { Box } from "@chakra-ui/react"
import AdditionalUserRightsList from "../features/additionalUserRights/components/AdditionalUserRightsList"

const SettingsSellerAccess: React.FC = () => {
  return (
    <Box p="5" my="4">
      <AdditionalUserRightsList />
    </Box>
  )
}

export default SettingsSellerAccess
