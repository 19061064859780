import React from "react"
import NoteTable from "../features/note/comonents/NoteTable"
import NoteHeader from "../features/note/comonents/NoteHeader"

const HomePage: React.FC = () => {
  return (
    <>
      <NoteHeader />
      <NoteTable />
    </>
  )
}

export default HomePage
