import React from "react"
import Select from "react-select"
import { chakra, Flex, Skeleton } from "@chakra-ui/react"
import { LoadStatus } from "../../types/common"
import {
  createMonthOptions,
  createYearOptions,
  getMonthList,
  getYearList,
} from "../../utils"
const ChakraReactSelect = chakra(Select)

interface OptionType {
  value: number | string
  label: string
}

interface MonthYearPickerProps {
  onMonthChange: (month: string) => void
  onYearChange: (year: string) => void
  status?: LoadStatus
  selectedMonth?: number
  selectedYear?: number
}

const MonthYearPicker: React.FC<MonthYearPickerProps> = ({
  onMonthChange,
  onYearChange,
  status,
  selectedMonth,
  selectedYear,
}) => {
  const monthOptions = createMonthOptions(getMonthList())
  const yearOptions = createYearOptions(getYearList())

  const selectedMonthOption = selectedMonth
    ? monthOptions.find((option) => option.value === selectedMonth)
    : null

  const selectedYearOption = selectedYear
    ? yearOptions.find((option) => option.value === selectedYear)
    : null

  const customStyles = {
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 9999,
    }),
  }

  const handleMonthChange = (option: unknown) => {
    if (typeof option === "object" && option !== null && "value" in option) {
      const selectedOption = option as OptionType
      onMonthChange(selectedOption.value.toString())
    }
  }

  const handleYearChange = (option: unknown) => {
    if (typeof option === "object" && option !== null && "value" in option) {
      const selectedOption = option as OptionType
      onYearChange(selectedOption.value.toString())
    }
  }

  return (
    <Flex>
      {status === LoadStatus.Loading || status === LoadStatus.Idle ? (
        <Skeleton w={112} h="40px" mr="2" />
      ) : (
        <ChakraReactSelect
          w={110}
          placeholder="Місяць"
          options={monthOptions}
          onChange={handleMonthChange}
          styles={customStyles}
          menuPortalTarget={document.body}
          value={selectedMonthOption}
          mr="2"
        />
      )}

      {status === LoadStatus.Loading || status === LoadStatus.Idle ? (
        <Skeleton w={90} h="40px" />
      ) : (
        <ChakraReactSelect
          w={100}
          placeholder="Рік"
          options={yearOptions}
          onChange={handleYearChange}
          styles={customStyles}
          menuPortalTarget={document.body}
          value={selectedYearOption}
        />
      )}
    </Flex>
  )
}

export default MonthYearPicker
