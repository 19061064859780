import { createAsyncThunk } from "@reduxjs/toolkit"
import axiosInstance from "../../api/axiosConfig"
import { Note } from "../../types/note"
import { handleAsyncThunkError } from "../../utils"

interface GetSellerNotesCountDto {
  sellerId: string
  date: Date
}

export const fetchNotes = createAsyncThunk(
  "get-notes-list",
  handleAsyncThunkError(async (params: any) => {
    const response = await axiosInstance.post("get-notes-list", params)
    return response.data
  }),
)

export const addNote = createAsyncThunk(
  "notes/addNote",
  handleAsyncThunkError(async (noteData: Partial<Note>) => {
    const response = await axiosInstance.post("create-note", noteData)
    return response.data
  }),
)

export const updateNote = createAsyncThunk(
  "notes/updateNote",
  handleAsyncThunkError(
    async (noteData: Partial<Note>, { rejectWithValue }) => {
      const response = await axiosInstance.patch(`update-note`, noteData)
      return response.data
    },
  ),
)

export const deleteNote = createAsyncThunk(
  "notes/deleteNote",
  handleAsyncThunkError(async (noteId: number) => {
    await axiosInstance.delete(`/delete-note/?id=${noteId}`)
    return noteId
  }),
)

export const getSellerNotesCount = createAsyncThunk(
  "notes/getSellerNotesCount",
  handleAsyncThunkError(
    async (getSellerNotesCountDto: GetSellerNotesCountDto) => {
      const response = await axiosInstance.post(
        "get-seller-notes-count",
        getSellerNotesCountDto,
      )

      return response.data
    },
  ),
)
