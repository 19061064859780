import React from "react"
import { Box } from "@chakra-ui/react"
import { SettingItem } from "../types/setting"
import SettingsList from "../features/settings/components/SettingsList"
import { cfg } from "../cfg"

const settingsList: readonly SettingItem[] = cfg.settingsList

const SettingsPage: React.FC = () => {
  return (
    <Box p="5" my="4">
      <SettingsList settingsList={settingsList} />
    </Box>
  )
}

export default SettingsPage
