import { showToastError, showToastSuccess } from "./showAlert"

const showCreateSuccessAlert = () => {
  showToastSuccess({ message: "Запис успішно додано!" })
}

const showCreateErrorAlert = (message: string | undefined) => {
  showToastError({
    message: `Помилка при додаванні запису: ${message || "500"}`,
  })
}

const showUpdateSuccessAlert = () => {
  showToastSuccess({ message: "Запис успішно оновлено!" })
}

const showUpdateErrorAlert = (message: string | undefined) => {
  showToastError({
    message: `Помилка при оновленні запису: ${message || "500"}`,
  })
}

const showDeleteSuccessAlert = () => {
  showToastSuccess({ message: "Запис успішно видалено!" })
}

const showDeleteErrorAlert = (message: string | undefined) => {
  showToastError({
    message: `Помилка при видаленні запису: ${message || "500"}`,
  })
}

export {
  showCreateSuccessAlert,
  showCreateErrorAlert,
  showUpdateSuccessAlert,
  showUpdateErrorAlert,
  showDeleteSuccessAlert,
  showDeleteErrorAlert,
}
