import React, { useEffect } from "react"
import { Button, Flex } from "@chakra-ui/react"
import { FieldValues, useForm } from "react-hook-form"
import CustomForm from "../../../components/CustomForm/CustomForm"
import { cfg } from "../../../cfg"
import { AppInput } from "../../../components/inputs/AppInput"
import { AppNumberInput } from "../../../components/inputs/AppNumberInput"
import { LocationInfo } from "../../../types/location-info"

interface EntryFormProps {
  isOpen: boolean
  onClose: () => void
  submitTitle: string
  title: string
  handler: (data: LocationInfo) => Promise<void>
  currentObject?: LocationInfo
}

const LocationInfoForm: React.FC<EntryFormProps> = ({
  isOpen,
  onClose,
  submitTitle,
  title,
  handler,
  currentObject,
}) => {
  const {
    control,
    handleSubmit: useFormSubmit,
    formState: { errors, isSubmitting },
    reset,
    watch,
  } = useForm({
    defaultValues: cfg.locationInfoForm.defaultValues,
  })

  // auto selects
  useEffect(() => {
    if (isOpen && currentObject) {
      const formDefaultValues: any = {}

      formDefaultValues[cfg.locationInfoForm.fields.rate.name] =
        currentObject.rate.toString()

      formDefaultValues[cfg.locationInfoForm.fields.percentage.name] =
        currentObject.percentage.toString()

      formDefaultValues[cfg.locationInfoForm.fields.type.name] =
        currentObject.type.toString()

      reset(formDefaultValues)
    }
  }, [currentObject, isOpen, reset])

  const onSubmit = async (data: any) => {
    await handler(data)
  }

  const handleFormSubmit = async (data: FieldValues) => {
    await onSubmit(data)
    reset()
    onClose()
  }

  const footer = (
    <Button
      isLoading={isSubmitting}
      type="submit"
      colorScheme="yellow"
      width="100%"
    >
      {submitTitle}
    </Button>
  )

  return (
    <CustomForm
      title={title}
      isOpen={isOpen}
      onClose={() => {
        onClose()
        reset()
      }}
      useFormSubmit={useFormSubmit}
      modalFooter={footer}
      handleSubmit={handleFormSubmit}
    >
      <Flex direction="column" gap="4">
        <AppInput
          control={control}
          placeholder={cfg.locationInfoForm.fields.type.placeholder}
          rules={{
            required: cfg.useFormRules.requiredMsg,
            maxLength: cfg.useFormRules.maxLength(48),
          }}
          name={cfg.locationInfoForm.fields.type.name}
          helperText={cfg.locationInfoForm.fields.type.helperText}
          label={cfg.locationInfoForm.fields.type.label}
          errors={errors}
        />
        <AppNumberInput
          control={control}
          placeholder={cfg.locationInfoForm.fields.rate.placeholder}
          rules={{ required: cfg.useFormRules.requiredMsg }}
          name={cfg.locationInfoForm.fields.rate.name}
          helperText={cfg.locationInfoForm.fields.rate.helperText}
          label={cfg.locationInfoForm.fields.rate.label}
          errors={errors}
        />
        <AppNumberInput
          control={control}
          placeholder={cfg.locationInfoForm.fields.percentage.placeholder}
          rules={{ required: cfg.useFormRules.requiredMsg }}
          name={cfg.locationInfoForm.fields.percentage.name}
          helperText={cfg.locationInfoForm.fields.percentage.helperText}
          label={cfg.locationInfoForm.fields.percentage.label}
          errors={errors}
        />
      </Flex>
    </CustomForm>
  )
}

export default LocationInfoForm
