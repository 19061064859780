import React, { ReactElement } from "react"
import { IconButton, Tooltip } from "@chakra-ui/react"

interface TooltipIconButtonProps {
  label: string
  icon: ReactElement
  onClick: () => void
  size?: string
  sx?: object
  iconButtonProps?: object
}

const TooltipIconButton: React.FC<TooltipIconButtonProps> = ({
  label,
  icon,
  onClick,
  size = "sm",
  sx = {},
  iconButtonProps = {},
}) => {
  return (
    <Tooltip label={label} hasArrow>
      <IconButton
        icon={icon}
        aria-label={label}
        size={size}
        onClick={onClick}
        sx={{ position: "absolute", ...sx }}
        {...iconButtonProps}
      />
    </Tooltip>
  )
}

export default TooltipIconButton
