import React, { useEffect } from "react"
import { Button, Flex } from "@chakra-ui/react"
import { FieldValues, useForm } from "react-hook-form"
import { AppSelectInputWithSearch } from "../../../components/inputs/AppSelectInputWithSearch"
import { useAppDispatch, useUserInfo } from "../../../app/hooks"
import CustomForm from "../../../components/CustomForm/CustomForm"
import { cfg } from "../../../cfg"

import { createSelectOptions, updateAccessRightList } from "../../../utils"
import { updateSellerAccess } from "../additionalUserRightsAPI"
import { updateSellerAccessDto } from "../../../types/access-rirhts"
import { showToastError } from "../../../common/showAlert"
import {
  showUpdateErrorAlert,
  showUpdateSuccessAlert,
} from "../../../common/apiCrudAlertHelper"

interface EntryFormProps {
  isOpen: boolean
  onClose: () => void
  shopList: string[]
  userId: string | null
  userName: string | null | undefined
}

const AdditionalUserRightsFormEdit: React.FC<EntryFormProps> = ({
  isOpen,
  onClose,
  shopList = [],
  userId,
  userName = "",
}) => {
  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit: useFormSubmit,
    formState: { errors, isSubmitting },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: cfg.userEditAccessForm.defaultValues,
  })

  const watchedValues = watch()

  useEffect(() => {
    if (shopList.length) {
      let shopListInNumbers: any = shopList.map((i) => parseFloat(i))
      reset({
        [cfg.userEditAccessForm.fields.shopIdList.name]: shopListInNumbers,
      })
    }
  }, [setValue, shopList])

  const onSubmit = async (data: FieldValues) => {
    if (userId) {
      await dispatch(
        updateSellerAccess({
          ...data,
          sellerId: parseFloat(userId),
        } as updateSellerAccessDto),
      )
        .unwrap()
        .then((originalPromiseResult) => {
          showUpdateSuccessAlert()
        })
        .catch((rejectedValueOrSerializedError) => {
          showUpdateErrorAlert(rejectedValueOrSerializedError.message)
        })
      await updateAccessRightList(dispatch)

      reset()
      onClose()
    } else {
      showToastError({
        message: `DeletingNoteId not provided`,
      })
    }
  }

  const handleFormSubmit = async (data: FieldValues) => {
    await onSubmit(data)
    reset()
    onClose()
  }

  const userInfo = useUserInfo()

  const shopOptions = createSelectOptions(
    userInfo.user?.availableShopList || [],
  )

  const footer = (
    <Button
      isLoading={isSubmitting}
      type="submit"
      colorScheme="yellow"
      width="100%"
    >
      Оновити
    </Button>
  )

  return (
    <CustomForm
      title={`Налаштувати додаткові права для ${userName}`}
      isOpen={isOpen}
      onClose={() => {
        onClose()
        reset()
      }}
      useFormSubmit={useFormSubmit}
      modalFooter={footer}
      handleSubmit={handleFormSubmit}
    >
      <Flex direction="column" gap="4">
        <AppSelectInputWithSearch
          value={watchedValues[
            cfg.userEditAccessForm.fields.shopIdList.name
          ].map((shopId) => parseFloat(shopId))}
          w={"100%"}
          placeholder={cfg.userEditAccessForm.fields.shopIdList.placeholder}
          control={control}
          isMulti
          name={cfg.userEditAccessForm.fields.shopIdList.name}
          rules={{
            required: cfg.useFormRules.requiredMsg,
            validate: cfg.useFormRules.validateShopSelection,
          }}
          label={cfg.userEditAccessForm.fields.shopIdList.label}
          options={shopOptions}
          errors={errors}
        />
      </Flex>
    </CustomForm>
  )
}

export default AdditionalUserRightsFormEdit
