import React from "react"
import { Button, HStack, Text } from "@chakra-ui/react"

interface PaginationProps {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
}
const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const firstPage = 1
  const lastPage = totalPages

  const paginationRange = () => {
    let start = Math.max(firstPage, currentPage - 2)
    let end = Math.min(lastPage, currentPage + 2)

    let range = []
    for (let i = start; i <= end; i++) {
      range.push(i)
    }

    if (start > firstPage) {
      range.unshift("...")
      range.unshift(firstPage)
    }

    if (end < lastPage) {
      range.push("...")
      range.push(lastPage)
    }

    return range
  }

  return (
    <HStack spacing="20px" justifyContent="center" p="5">
      <Button
        onClick={() => onPageChange(currentPage - 1)}
        isDisabled={currentPage === 1}
      >
        Попередня
      </Button>
      {paginationRange().map((page, index) =>
        typeof page === "number" ? (
          <Button
            key={index}
            onClick={() => onPageChange(page)}
            colorScheme={page === currentPage ? "yellow" : "gray"}
          >
            {page}
          </Button>
        ) : (
          <Text key={index} mx="2">
            ...
          </Text>
        ),
      )}
      <Button
        onClick={() => onPageChange(currentPage + 1)}
        isDisabled={currentPage === totalPages}
      >
        Наступна
      </Button>
    </HStack>
  )
}

export default Pagination
