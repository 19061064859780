import { createSlice } from "@reduxjs/toolkit"
import { LoadStatus, SliceErrorType } from "../../types/common"
import {
  createLocationInfo,
  deleteLocationInfo,
  fetchLocationInfoList,
  updateLocationInfo,
} from "./locationInfoAPI"
import { LocationInfo } from "../../types/location-info"

interface LocationInfoState {
  locationInfoConfigList: LocationInfo[]
  fetchStatus: LoadStatus
  createStatus: LoadStatus
  updateStatus: LoadStatus
  deleteStatus: LoadStatus
  fetchError: SliceErrorType
  addError: SliceErrorType
  updateError: SliceErrorType
  deleteError: SliceErrorType
}

const initialState: LocationInfoState = {
  locationInfoConfigList: [],
  fetchStatus: LoadStatus.Idle,
  createStatus: LoadStatus.Idle,
  updateStatus: LoadStatus.Idle,
  deleteStatus: LoadStatus.Idle,
  fetchError: null,
  addError: null,
  updateError: null,
  deleteError: null,
}

const locationInfoSlice = createSlice({
  name: "locationInfo",
  initialState,
  reducers: {
    clearError: (state) => {
      state.fetchError = null
      state.addError = null
      state.updateError = null
      state.deleteError = null
    },
  },
  extraReducers: (builder) => {
    builder
      // фетч записів
      .addCase(fetchLocationInfoList.pending, (state) => {
        state.fetchStatus = LoadStatus.Loading
        state.fetchError = null
      })
      .addCase(fetchLocationInfoList.fulfilled, (state, action) => {
        state.fetchStatus = LoadStatus.Succeeded
        state.locationInfoConfigList = action.payload
      })
      .addCase(fetchLocationInfoList.rejected, (state, action) => {
        state.fetchStatus = LoadStatus.Failed
        state.fetchError = action.payload.message
      })
      // додавання запису
      .addCase(createLocationInfo.pending, (state) => {
        state.createStatus = LoadStatus.Loading
      })
      .addCase(createLocationInfo.fulfilled, (state, action) => {
        state.createStatus = LoadStatus.Succeeded
        state.locationInfoConfigList.push(action.payload[0])
      })
      .addCase(createLocationInfo.rejected, (state, action) => {
        state.createStatus = LoadStatus.Failed
        state.addError = action.error.message
      })
      // оновлення запису
      .addCase(updateLocationInfo.pending, (state) => {
        state.updateStatus = LoadStatus.Loading
      })
      .addCase(updateLocationInfo.fulfilled, (state, action) => {
        state.updateStatus = LoadStatus.Succeeded
        const index = state.locationInfoConfigList.findIndex(
          (loc) => loc.id === action.payload.id,
        )
        if (index !== -1) {
          state.locationInfoConfigList[index] = action.payload
        }
      })
      .addCase(updateLocationInfo.rejected, (state, action) => {
        state.updateStatus = LoadStatus.Failed
        state.updateError = action.error.message
      })
      // видалення
      .addCase(deleteLocationInfo.pending, (state) => {
        state.deleteStatus = LoadStatus.Loading
      })
      .addCase(deleteLocationInfo.fulfilled, (state, action) => {
        state.deleteStatus = LoadStatus.Succeeded
        state.locationInfoConfigList = state.locationInfoConfigList.filter(
          (loc) => loc.id !== action.payload,
        )
      })
      .addCase(deleteLocationInfo.rejected, (state, action) => {
        state.deleteStatus = LoadStatus.Failed
        state.deleteError = action.error.message
      })
  },
})

export const { clearError } = locationInfoSlice.actions
export default locationInfoSlice.reducer
