import React, { FC, ReactNode } from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react"

interface UniversalModalProps {
  isOpen: boolean
  onClose: () => void
  title: string
  children: ReactNode
  footerContent?: ReactNode
}

const UniversalModal: FC<UniversalModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  footerContent,
}) => {
  return (
    <Modal scrollBehavior="inside" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        {footerContent && <ModalFooter>{footerContent}</ModalFooter>}
      </ModalContent>
    </Modal>
  )
}

export default UniversalModal
