import React from "react"
import { Badge, Box, Text } from "@chakra-ui/react"

interface InfoRowProps {
  label: string | undefined
  value: string | number | undefined
}

const InfoRow: React.FC<InfoRowProps> = ({ label, value }) => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      pb={2}
      pt={2}
      borderRadius="lg"
      bg="white"
      width="100%"
      mb={2}
    >
      <Badge colorScheme="yellow" variant="subtle" fontSize="0.9em">
        {label}:
      </Badge>
      <Text fontSize="xl" color="green.500" ml={2}>
        {value}
      </Text>
    </Box>
  )
}

export default InfoRow
