import React from "react"
import { Box, Flex, Image, Text } from "@chakra-ui/react"
import iconImage from "../../../vandal.png"

interface NoteHeaderWrapperProps {
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
  children?: React.ReactNode
}

const NoteHeaderWrapper: React.FC<NoteHeaderWrapperProps> = ({
  leftContent,
  rightContent,
  children,
}) => {
  return (
    <Box width="full">
      <Flex
        justifyContent="space-between"
        mb="4"
        alignItems="center"
        p="5"
        pb={0}
        wrap="wrap"
        width="full"
      >
        <Flex alignItems="center" mr="2">
          <Image src={iconImage} boxSize="50px" mr="2" alt="Статистика ПК" />
          <Text fontSize="2xl" fontWeight="bold" mr="4">
            Статистика&nbsp;ПК
          </Text>

          {leftContent && leftContent}
        </Flex>
        {rightContent && <Flex>{rightContent}</Flex>}
        {children && children}
      </Flex>
    </Box>
  )
}

export default NoteHeaderWrapper
