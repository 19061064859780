import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react"
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
} from "react-hook-form"

type Props<T extends Partial<FieldValues>> = {
  control: Control<T>
  name: Path<T>
  label?: string
  helperText?: string
  errors: any
  rules?: RegisterOptions
  placeholder?: string
  value?: string
}

export const AppNumberInput = <T extends FieldValues>({
  control,
  name,
  label,
  helperText,
  errors,
  rules,
  placeholder,
  value,
}: Props<T>) => {
  return (
    <FormControl isInvalid={!!errors?.[name]}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Controller
        control={control}
        name={name}
        rules={{
          ...rules,
          validate: {
            max: (value: number) =>
              value <= 999999 || "Максимальне значення - 999999",
            min: (value: number) =>
              value >= -999999 || "Мінімальне значення -999999",
          },
        }}
        render={({ field }) => (
          <NumberInput
            defaultValue={value || field.value}
            onChange={(val) => field.onChange(val)}
          >
            <NumberInputField {...field} placeholder={placeholder} />
            <NumberInputStepper>
              <NumberIncrementStepper color="#848484" />
              <NumberDecrementStepper color="#848484" />
            </NumberInputStepper>
          </NumberInput>
        )}
      />
      {!!errors?.[name] ? (
        <FormErrorMessage>{errors?.[name].message}</FormErrorMessage>
      ) : (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}
