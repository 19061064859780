import React from "react"
import {
  Box,
  SimpleGrid,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { SettingItem } from "../../../types/setting"

interface SettingsTileListProps {
  settingsList: readonly SettingItem[]
}

const SettingsList: React.FC<SettingsTileListProps> = ({ settingsList }) => {
  const navigate = useNavigate()
  const hoverBgColor = useColorModeValue("yellow.100", "yellow.600")
  const bgColor = useColorModeValue("gray.50", "gray.700")
  const textColor = useColorModeValue("gray.800", "white")
  const descColor = useColorModeValue("gray.600", "gray.200")

  const handleSettingClick = (path: string) => {
    navigate(path)
  }

  return (
    <SimpleGrid columns={[1, 2, 3]} spacing="20px">
      {settingsList.map((setting) => (
        <Tooltip key={setting.id} placement="top" hasArrow>
          <Box
            p="5"
            boxShadow="md"
            borderRadius="lg"
            bg={bgColor}
            _hover={{ bg: hoverBgColor, cursor: "pointer" }}
            height="auto"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            onClick={() => handleSettingClick(setting.path)}
          >
            <Text
              fontSize="lg"
              fontWeight="bold"
              color={textColor}
              mb="2"
              noOfLines={1}
            >
              {setting.title}
            </Text>
            <Text fontSize="md" color={descColor} noOfLines={5}>
              {setting.description}
            </Text>
          </Box>
        </Tooltip>
      ))}
    </SimpleGrid>
  )
}

export default SettingsList
