import { createSlice } from "@reduxjs/toolkit"
import {
  createAccessRights,
  deleteUserAccessRights,
  fetchAdditionalUserRights,
  updateSellerAccess,
} from "./additionalUserRightsAPI"
import { LoadStatus, SliceErrorType } from "../../types/common"

interface AccessRightsState {
  loadingStatus: LoadStatus
  createStatus: LoadStatus
  updateStatus: LoadStatus
  deleteStatus: LoadStatus
  fetchError: SliceErrorType
  createError: SliceErrorType
  updateError: SliceErrorType
  deleteError: SliceErrorType
  rightsList: any[]
}

const initialState: AccessRightsState = {
  loadingStatus: LoadStatus.Idle,
  createStatus: LoadStatus.Idle,
  updateStatus: LoadStatus.Idle,
  deleteStatus: LoadStatus.Idle,
  fetchError: null,
  createError: null,
  updateError: null,
  deleteError: null,
  rightsList: [],
}

const accessRightsSlice = createSlice({
  name: "accessRights",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.fetchError = null
      state.createError = null
      state.updateError = null
    },
  },
  extraReducers: (builder) => {
    builder
      // Запит додаткових прав доступу
      .addCase(fetchAdditionalUserRights.pending, (state) => {
        state.loadingStatus = LoadStatus.Loading
      })
      .addCase(fetchAdditionalUserRights.fulfilled, (state, action) => {
        state.loadingStatus = LoadStatus.Succeeded
        state.rightsList = action.payload
        state.fetchError = null
      })
      .addCase(fetchAdditionalUserRights.rejected, (state, action) => {
        state.loadingStatus = LoadStatus.Failed
        state.fetchError = action.payload.message
      })
      // Оновлення прав доступу
      .addCase(updateSellerAccess.pending, (state) => {
        state.updateStatus = LoadStatus.Loading
      })
      .addCase(updateSellerAccess.fulfilled, (state) => {
        state.updateStatus = LoadStatus.Succeeded
        state.updateError = null
      })
      .addCase(updateSellerAccess.rejected, (state, action) => {
        state.updateStatus = LoadStatus.Failed
        state.updateError = action.payload.message
      })
      // Створення прав доступу
      .addCase(createAccessRights.pending, (state) => {
        state.createStatus = LoadStatus.Loading
      })
      .addCase(createAccessRights.fulfilled, (state) => {
        state.createStatus = LoadStatus.Succeeded
        state.createError = null
      })
      .addCase(createAccessRights.rejected, (state, action) => {
        state.createStatus = LoadStatus.Failed
        state.createError = action.payload.message
      })
      .addCase(deleteUserAccessRights.pending, (state) => {
        state.deleteStatus = LoadStatus.Loading
      })
      .addCase(deleteUserAccessRights.fulfilled, (state) => {
        state.deleteStatus = LoadStatus.Succeeded
        state.deleteError = null // Очистка помилки після успішного видалення
      })
      .addCase(deleteUserAccessRights.rejected, (state, action) => {
        state.deleteStatus = LoadStatus.Failed

        state.deleteError = action.payload.message
      })
  },
})

export const { clearErrors } = accessRightsSlice.actions

export default accessRightsSlice.reducer
