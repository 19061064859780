export const cfg = {
  statTable: {
    headColumnWidth: {
      data: { width: 120, title: "Дата" },
      seller: { width: 130, title: "Продавець" },
      shop: { width: 150, title: "Магазин" },
      inCard: { width: 120, title: "Картою" },
      inCash: { width: 120, title: "Готівкою" },
      total: { width: 120, title: "Всього" },
      margin: { width: 120, title: "Маржа" },
      rate: { width: 120, title: "Ставка" },
      percentFromMargin: { width: 120, title: "% від маржі" },
      calculatedSalaryPerDay: { width: 120, title: "ЗП в день" },
      actions: { width: 120, title: "Дії" },
    },
  },
  entryForm: {
    defaultValues: {
      date: new Date(),
      shopId: "",
      sellerId: "",
      getWithCard: "",
      getInCash: "",
      margin: "",
      totalSeverityOfChecks: "",
      checksToAnUnknownPerson: "",
      packages: "",
    },
    data: {
      name: "date",
      label: "Дата",
    },
    shop: {
      name: "shopId",
      label: "Магазин",
      placeholder: "Виберіть магазин",
      helperText: "",
    },
    seller: {
      name: "sellerId",
      label: "Продавець",
      placeholder: "Виберіть продавця",
      helperText: "",
    },
    getWithCard: {
      name: "getWithCard",
      label: "Отримано картою",
      placeholder: "Введіть суму",
      helperText: "",
    },
    getInCash: {
      name: "getInCash",
      label: "Отримано готівкою",
      placeholder: "Введіть суму",
      helperText: "",
    },
    margin: {
      name: "margin",
      label: "Маржа",
      placeholder: "Введіть маржу",
      helperText: "",
    },
    totalSeverityOfChecks: {
      name: "totalSeverityOfChecks",
      label: "Загальна к-сть чеків",
      placeholder: "Введіть к-сть чеків",
      helperText: "",
    },
    checksToAnUnknownPerson: {
      name: "checksToAnUnknownPerson",
      label: "Чеки на невідомого",
      placeholder: "Введіть к-сть чеків",
      helperText: "",
    },
    packages: {
      name: "packages",
      label: "Пакети",
      placeholder: "Введіть к-сть пакетів",
      helperText: "",
    },
    requiredMsg: "Це поле є обовʼязковим",
    labels: {
      allIncome: "Отримано всього",
      rate: "Ставка",
      percentFromMargin: (marginPercentage: string | number | undefined) =>
        `${marginPercentage}% від маржі`,
      salaryPerDay: "ЗП за день",
      unknownPercent: "% невідомих",
      packagesPercent: "% пакетів",
    },
  },
  alertStatus: {
    success: "success",
    error: "error",
  },
  reportForm: {
    defaultValues: {
      sellerId: "",
      year: "",
      month: "",
    },
    seller: {
      name: "sellerId",
      label: "Продавець",
      placeholder: "Виберіть продавця",
      helperText: "",
    },
    year: {
      name: "year",
      label: "Рік",
      placeholder: "Виберіть рік",
      helperText: "",
    },
    month: {
      name: "month",
      label: "Місяць",
      placeholder: "Виберіть місяць",
      helperText: "",
    },
    requiredMsg: "Це поле є обовʼязковим",
  },
  toolTipText: {
    editAllow: "Редагувати",
    editDoNotAllow:
      "Редагувати не можна (пройшло більше 3 днів від створення запису)",
    deleteAllow: "Видалити",
    deleteDoNotAllow: "Видалити запис може лише адміністратор",
  },
  settingsList: [
    {
      id: "/settings/user-access",
      title: "Надати додаткові права продавцеві",
      description:
        "Дозволяє додати продавця у інші віддлі у структурі, замість того щоб дублювати їх у структурі",
      path: "/settings/user-access",
    },
    {
      id: "/settings/user-salary-rate",
      title: "Налаштування ставки та відсотку",
      description:
        "Дозволяє змінити налаштування ставки: для району, міста, додати нову ставку та інше",
      path: "/settings/user-salary-rate",
    },
  ],
  userAccessForm: {
    defaultValues: {
      sellerId: "",
      shopIdList: [],
    },
    fields: {
      sellerId: {
        placeholder: "Оберіть продавця",
        label: "Кастомізації прав для:",
        name: "sellerId",
      },
      shopIdList: {
        placeholder: "Оберіть магазини",
        label: "Додаткові магазини, доступні працівникові",
        helperText: "",
        name: "shopIdList",
      },
    },
    submitTitles: {},
  },
  locationInfoForm: {
    defaultValues: {
      type: "",
      rate: "",
      percentage: "",
    },
    fields: {
      type: {
        placeholder: "Введіть новий тип",
        label: "Тип",
        name: "type",
        helperText: "Наприклад (місто/обл./район/Львів/Ужгород і тд.)",
      },
      rate: {
        placeholder: "Введіть ставку",
        label: "Ставка",
        helperText: "",
        name: "rate",
      },
      percentage: {
        placeholder: "Введіть відсоток",
        label: "Відсоток",
        helperText: "Дробові числа вводяться через крапку",
        name: "percentage",
      },
    },
    submitTitles: {},
  },
  userEditAccessForm: {
    defaultValues: {
      shopIdList: [],
    },
    fields: {
      shopIdList: {
        placeholder: "Оберіть магазини",
        label: "Додаткові магазини, доступні працівникові",
        helperText: "",
        name: "shopIdList",
      },
    },
  },
  currencyPrefix: "грн",
  unexpectedError: "Сталася непередбачувана помилка",
  useFormRules: {
    maxLength: (maxLength: number) => {
      return {
        value: maxLength,
        message: `Максимальна кількість символів – ${maxLength}`,
      }
    },
    requiredMsg: "Це поле є обовʼязковим",
    validateShopSelection: (value: any) =>
      value.length <= 10 || "Можна обрати не більше 10 магазинів",
  },
} as const
