import React, { Fragment, useEffect, useState } from "react"
import CustomForm from "../../../components/CustomForm/CustomForm"
import { Box, Button, Flex } from "@chakra-ui/react"
import { useForm, SubmitHandler, FieldValues } from "react-hook-form"
import { AppSelectInputWithSearch } from "../../../components/inputs/AppSelectInputWithSearch"
import { AppInputDatePicker } from "../../../components/inputs/AppInputDatePicker"
import { cfg } from "../../../cfg"
import {
  calculateAllIncome,
  calculateCup,
  calculatePackagesPercent,
  calculatePercentageFromMargin,
  calculateSalaryPerDay,
  createNoteDispatchDtoIn,
  createSelectOptions,
  determineStyledBoxProps,
  findSelectedSeller,
  findSelectedShop,
  getDefaultFormValues,
  StyledBox,
  validate3DaysRangeForDatePicker,
} from "../../../utils"
import { Seller, Shop, WatchedValues } from "../../../types/common"
import { AppNumberInput } from "../../../components/inputs/AppNumberInput"
import {
  useAppDispatch,
  useNotePageInfo,
  useSelections,
  useUserInfo,
} from "../../../app/hooks"
import InfoRow from "../../../components/InfoRow/InfoRow"
import iconImageVandal from "../../../../vandal.png"
import iconImageVH from "../../../../vh.png"
import { Note } from "../../../types/note"
import { getSellerNotesCount } from "../noteAPI"

interface EntryFormProps {
  title: string
  isOpen: boolean
  onClose: () => void
  sellerList?: Seller[]
  shopList?: Shop[]
  note?: Note
  handler: (data: any) => Promise<void>
  submitTitle: string
}

const EntryForm: React.FC<EntryFormProps> = ({
  title,
  sellerList = [],
  shopList = [],
  isOpen,
  onClose,
  note = undefined,
  handler,
  submitTitle,
}) => {
  const [rate, setRate] = useState<number>(0)

  const notePageInfo = useNotePageInfo()
  const userInfo = useUserInfo()
  const selections = useSelections()
  const dispatch = useAppDispatch()

  if (!sellerList.length) sellerList = userInfo.user?.availableUserList || []
  if (!shopList.length) shopList = userInfo.user?.availableShopList || []

  const {
    handleSubmit: useFormSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: getDefaultFormValues(note),
    shouldUnregister: false,
  })

  const watchedValues = watch()

  // auto selects
  useEffect(() => {
    if (isOpen) {
      const formDefaultValues: WatchedValues = getDefaultFormValues(note)
      if (sellerList.length === 1) {
        formDefaultValues[cfg.entryForm.seller.name] =
          sellerList[0].ID.toString()
      }
      if (shopList.length === 1) {
        formDefaultValues[cfg.entryForm.shop.name] = shopList[0].id.toString()
      }
      reset(formDefaultValues)
    }
  }, [sellerList, shopList, setValue, isOpen, note, reset])

  const sellerOptions = createSelectOptions(sellerList)
  const shopOptions = createSelectOptions(shopList)

  const selectedShop = findSelectedShop(shopList, watchedValues.shopId)

  const selectedShopMarginPercentage = selectedShop?.percent || 0
  // const selectedShopRate = selectedShop?.rate || 0

  const salaryPerDay = calculateSalaryPerDay(
    selectedShopMarginPercentage,
    rate,
    watchedValues.margin,
  )

  const percentageFromMargin = calculatePercentageFromMargin(
    watchedValues.margin,
    selectedShopMarginPercentage,
  )

  const cup = calculateCup(watchedValues)
  const packagesPercent = calculatePackagesPercent(watchedValues)
  const allIncome = calculateAllIncome(watchedValues)

  const fetchNotesCount = async () => {
    const getSellerNotesCountDto = {
      sellerId: watchedValues.sellerId,
      date: watchedValues.date,
    }

    const response = await dispatch(getSellerNotesCount(getSellerNotesCountDto))

    return response.payload.notesCount
  }

  const setCurrentRate = () => {
    if (selectedShop) {
      setRate(selectedShop.rate)
    } else {
      setRate(0)
    }
  }

  console.log("--->rate<---", rate)

  useEffect(() => {
    if (watchedValues.sellerId && watchedValues.date) {
      fetchNotesCount().then((sellerNotesCount) => {
        console.log("--->sellerNotesCount<---", sellerNotesCount)

        if (sellerNotesCount >= 20) {
          setRate(800)
        } else {
          setCurrentRate()
        }
      })
    }
  }, [watchedValues.sellerId, watchedValues.date, selectedShop])

  const footer = (
    <Button
      isLoading={isSubmitting}
      type="submit"
      colorScheme="yellow"
      width="100%"
    >
      {submitTitle}
    </Button>
  )

  const handleSubmit: SubmitHandler<FieldValues> = async (values) => {
    const selectedSeller = findSelectedSeller(sellerList, values.sellerId)

    const totalReceipts = (
      parseFloat(values.getWithCard) + parseFloat(values.getInCash)
    ).toString()

    const addNoteDtoIn = {
      id: note?.id,
      cardReceipts: values.getWithCard,
      cashReceipts: values.getInCash,
      responsibleId: parseFloat(values.sellerId),
      shopId: parseFloat(values.shopId),
      rate,
      percentage: selectedShopMarginPercentage.toString(),
      margin: values.margin,
      date: values.date,
      createId: userInfo.user && parseFloat(userInfo.user.userId),
      totalReceipts: totalReceipts,
      responsibleName: selectedSeller?.FullName,
      shopName: `${selectedShop?.firstName} ${selectedShop?.lastName}`,
      packages: parseFloat(values.packages),
      cup: parseFloat(values.checksToAnUnknownPerson),
      totalChecks: parseFloat(values.totalSeverityOfChecks),
    }

    const noteFetchParams = createNoteDispatchDtoIn(
      userInfo,
      selections,
      notePageInfo,
    )

    await handler({ noteDate: addNoteDtoIn, noteParams: noteFetchParams })
    onClose()
    reset(cfg.entryForm.defaultValues)
  }

  const handleClose = () => {
    onClose()
    reset(cfg.entryForm.defaultValues)
  }

  const { iconImage } = determineStyledBoxProps(
    selectedShop?.firstName || "",
    iconImageVandal,
    iconImageVH,
  )

  useEffect(() => {}, [selectedShop])

  const rightSide = (
    <StyledBox iconImage={iconImage}>
      <InfoRow
        label={cfg.entryForm.labels.allIncome}
        value={`${allIncome} грн`}
      />
      <InfoRow label={cfg.entryForm.labels.rate} value={`${rate} грн`} />
      <InfoRow
        label={cfg.entryForm.labels.percentFromMargin(
          selectedShopMarginPercentage,
        )}
        value={`${percentageFromMargin} грн`}
      />
      <InfoRow
        label={cfg.entryForm.labels.salaryPerDay}
        value={`${salaryPerDay} грн`}
      />
      <InfoRow label={cfg.entryForm.labels.unknownPercent} value={`${cup} %`} />
      <InfoRow
        label={cfg.entryForm.labels.packagesPercent}
        value={`${packagesPercent} %`}
      />
    </StyledBox>
  )

  const leftSide = (
    <Box marginRight={4}>
      <AppInputDatePicker
        control={control}
        value={watchedValues.date}
        name={cfg.entryForm.data.name}
        label={cfg.entryForm.data.label}
        rules={{
          required: cfg.useFormRules.requiredMsg,
          ...(!userInfo?.user?.isAdmin && {
            validate: {
              validDateRange: validate3DaysRangeForDatePicker,
            },
          }),
        }}
        errors={errors}
      />
      <AppSelectInputWithSearch
        w={210}
        control={control}
        value={parseFloat(watchedValues.shopId)}
        name={cfg.entryForm.shop.name}
        label={cfg.entryForm.shop.label}
        helperText={cfg.entryForm.shop.helperText}
        placeholder={cfg.entryForm.shop.placeholder}
        options={shopOptions}
        rules={{ required: cfg.useFormRules.requiredMsg }}
        errors={errors}
      />
      <AppSelectInputWithSearch
        w={210}
        control={control}
        value={parseFloat(watchedValues.sellerId)}
        name={cfg.entryForm.seller.name}
        label={cfg.entryForm.seller.label}
        helperText={cfg.entryForm.seller.helperText}
        placeholder={cfg.entryForm.seller.placeholder}
        options={sellerOptions}
        rules={{ required: cfg.useFormRules.requiredMsg }}
        errors={errors}
      />
      <AppNumberInput
        control={control}
        value={watchedValues.getWithCard}
        name={cfg.entryForm.getWithCard.name}
        label={cfg.entryForm.getWithCard.label}
        helperText={cfg.entryForm.getWithCard.helperText}
        placeholder={cfg.entryForm.getWithCard.placeholder}
        rules={{ required: cfg.useFormRules.requiredMsg }}
        errors={errors}
      />
      <AppNumberInput
        control={control}
        value={watchedValues.getInCash}
        name={cfg.entryForm.getInCash.name}
        label={cfg.entryForm.getInCash.label}
        helperText={cfg.entryForm.getInCash.helperText}
        placeholder={cfg.entryForm.getInCash.placeholder}
        rules={{ required: cfg.useFormRules.requiredMsg }}
        errors={errors}
      />
      <AppNumberInput
        control={control}
        value={watchedValues.margin}
        name={cfg.entryForm.margin.name}
        label={cfg.entryForm.margin.label}
        helperText={cfg.entryForm.margin.helperText}
        placeholder={cfg.entryForm.margin.placeholder}
        rules={{ required: cfg.useFormRules.requiredMsg }}
        errors={errors}
      />
      <AppNumberInput
        control={control}
        value={watchedValues.totalSeverityOfChecks}
        name={cfg.entryForm.totalSeverityOfChecks.name}
        label={cfg.entryForm.totalSeverityOfChecks.label}
        helperText={cfg.entryForm.totalSeverityOfChecks.helperText}
        placeholder={cfg.entryForm.totalSeverityOfChecks.placeholder}
        rules={{ required: cfg.useFormRules.requiredMsg }}
        errors={errors}
      />
      <AppNumberInput
        control={control}
        value={watchedValues.checksToAnUnknownPerson}
        name={cfg.entryForm.checksToAnUnknownPerson.name}
        label={cfg.entryForm.checksToAnUnknownPerson.label}
        helperText={cfg.entryForm.checksToAnUnknownPerson.helperText}
        placeholder={cfg.entryForm.checksToAnUnknownPerson.placeholder}
        rules={{ required: cfg.useFormRules.requiredMsg }}
        errors={errors}
      />
      <AppNumberInput
        control={control}
        value={watchedValues.packages}
        name={cfg.entryForm.packages.name}
        label={cfg.entryForm.packages.label}
        helperText={cfg.entryForm.packages.helperText}
        placeholder={cfg.entryForm.packages.placeholder}
        rules={{ required: cfg.useFormRules.requiredMsg }}
        errors={errors}
      />
    </Box>
  )

  return (
    <Fragment>
      <CustomForm
        title={title}
        isOpen={isOpen}
        onClose={handleClose}
        useFormSubmit={useFormSubmit}
        modalFooter={footer}
        handleSubmit={handleSubmit}
      >
        <Flex>
          {leftSide}
          {rightSide}
        </Flex>
      </CustomForm>
    </Fragment>
  )
}

export default EntryForm
