import React from "react"
import { Box } from "@chakra-ui/react"
import LocationInfoList from "../features/locationInfo/components/LocationInfoList"

const SettingsSalaryRate: React.FC = () => {
  return (
    <Box p="5" my="4">
      <LocationInfoList />
    </Box>
  )
}

export default SettingsSalaryRate
