import axiosInstance from "./axiosConfig"
import { ReportData } from "../types/report"

export async function fetchUserAccessRightsByIdDirectly(userId: string) {
  try {
    const response = await axiosInstance.post("get-seller-access-by-userid", {
      userId: userId.toString(),
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export async function createReport(reportData: ReportData) {
  try {
    const response = await axiosInstance.post("get-report", reportData)
    return response.data
  } catch (error) {
    throw error
  }
}
