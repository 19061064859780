export enum LoadStatus {
  Idle = "idle",
  Loading = "loading",
  Succeeded = "succeeded",
  Failed = "failed",
}

export enum Department {
  VapeHub,
  Vandal,
}
export interface PageInfo {
  pageIndex: number
  pageSize: number
  total: number
}

export interface Shop {
  id: number
  firstName: string
  lastName: string
  state: string
  rate: number
  percent: number
}

export interface Seller {
  ID: number
  FullName: string
  WORK_POSITION: string | null
}

export type CustomSelectItem = Shop | Seller

export interface StyledBoxProps {
  iconImage: string | undefined
}

export interface WatchedValues {
  margin: string
  shopId: string
  sellerId: string
  checksToAnUnknownPerson: string
  totalSeverityOfChecks: string
  packages: string
  getWithCard: string
  getInCash: string
  date: Date
}

export interface UserFromAvailableUserList {
  ID: string
  FullName: string
}

export interface RightItem {
  user_id: string
  shop_id_list: string[]
}

export type AppError = Error & {
  response?: {
    data?: {
      message?: string
    }
  }
}

export interface HeaderUserIconProps {
  userName: string | undefined | null
  userImageLink: string | undefined | null
}

export type ChakraSize = "xs" | "sm" | "md" | "lg" | "xl"

export type SliceErrorType = string | null | undefined
