import React, { FC, ReactNode } from "react"
import CustomModal from "../Modal/Modal"
import { ModalFooter } from "@chakra-ui/react"
import {
  SubmitHandler,
  UseFormHandleSubmit,
  FieldValues,
} from "react-hook-form"
import { ChakraSize } from "../../types/common"

interface CustomFormProps {
  title: string
  isOpen: boolean
  onClose: () => void
  useFormSubmit: UseFormHandleSubmit<FieldValues>
  modalFooter: ReactNode
  children: ReactNode
  handleSubmit: SubmitHandler<FieldValues>
  modalSize?: ChakraSize
}

const CustomForm: FC<CustomFormProps> = ({
  title,
  isOpen,
  onClose,
  useFormSubmit,
  modalFooter,
  children,
  handleSubmit,
  modalSize = "xl",
}) => {
  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      size={modalSize}
    >
      <form onSubmit={useFormSubmit(handleSubmit)}>
        {children}
        {modalFooter && (
          <ModalFooter paddingRight={0} paddingLeft={0}>
            {modalFooter}
          </ModalFooter>
        )}
      </form>
    </CustomModal>
  )
}

export default CustomForm
