import { createStandaloneToast } from "@chakra-ui/react"

const { toast } = createStandaloneToast()

const showToastSuccess = ({ message }: { message: string }) => {
  toast({
    title: "Успіх",
    description: message,
    status: "success",
    duration: 5000,
    isClosable: true,
    position: "top",
    containerStyle: {
      marginTop: "1rem",
    },
    variant: "top-accent",
  })
}

const showToastError = ({ message }: { message: string }) => {
  toast({
    title: "Помилка",
    description: message,
    status: "error",
    duration: 5000,
    isClosable: true,
    position: "top",
    containerStyle: {
      marginTop: "1rem",
    },
  })
}

export { showToastError, showToastSuccess }
