import React, { Fragment, useState } from "react"
import { Center, Flex, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react"
import { CreateEntryModalHandlerArgs, Note } from "../../../types/note"
import TableHeader from "./TableHeader"
import { TableSkeleton } from "../../../components/TableSkeleton/TableSkeleton"
import TableRow from "./TableRow"
import EntryForm from "../forms/EntryForm"
import { deleteNote, fetchNotes, updateNote } from "../noteAPI"
import {
  useAppDispatch,
  useNoteList,
  useNotePageInfo,
  useSelections,
  useUserInfo,
} from "../../../app/hooks"
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal"
import { createNoteDispatchDtoIn, updateNoteList } from "../../../utils"
import { useSelector } from "react-redux"
import { selectAllErrors, selectAllStatuses } from "../noteSlice"
import { LoadStatus } from "../../../types/common"
import ViewModal from "../modal/ViewEntryModal"
import {
  showDeleteErrorAlert,
  showDeleteSuccessAlert,
  showUpdateErrorAlert,
  showUpdateSuccessAlert,
} from "../../../common/apiCrudAlertHelper"

interface TableComponentProps {}
const PCTable: React.FC<TableComponentProps> = () => {
  const dispatch = useAppDispatch()

  const noteList = useNoteList()

  const notesError = useSelector(selectAllErrors)
  const noteStatuses = useSelector(selectAllStatuses)

  const { fetchStatus } = noteStatuses
  const { fetchError } = notesError

  const notePageInfo = useNotePageInfo()
  const userInfo = useUserInfo()
  const selections = useSelections()

  const selectionsHasTrueValues = Object.values(selections).some(
    (value) => value,
  )

  const noteFetchParams = createNoteDispatchDtoIn(
    userInfo,
    selections,
    notePageInfo,
  )

  const [isEditFormOpen, setEditFormOpen] = useState(false)
  const [selectedNote, setSelectedNote] = useState<Note | undefined>(undefined)
  const [isConfirmOpen, setConfirmOpen] = useState(false)
  const [deletingNoteId, setDeletingNoteId] = useState<number | null>(null)
  const [isViewModalOpen, setViewModalOpen] = useState(false)

  const shopList = userInfo.user?.availableShopList || []
  const handleView = (note: Note) => {
    setSelectedNote(note)
    setViewModalOpen(true)
  }
  const handleCloseViewModal = () => {
    setViewModalOpen(false)
    setSelectedNote(undefined)
  }

  const handleEdit = (note: Note) => {
    setSelectedNote(note)
    setEditFormOpen(true)
  }

  const handleDelete = (noteId: number) => {
    setDeletingNoteId(noteId)
    setConfirmOpen(true)
  }

  const handleCloseEditForm = () => {
    setEditFormOpen(false)
    setSelectedNote(undefined)
  }

  const handleConfirmDelete = async () => {
    if (deletingNoteId !== null) {
      await dispatch(deleteNote(deletingNoteId))
        .unwrap()
        .then((originalPromiseResult) => {
          showDeleteSuccessAlert()
        })
        .catch((rejectedValueOrSerializedError) => {
          showDeleteErrorAlert(rejectedValueOrSerializedError.message)
        })

      await updateNoteList(dispatch, noteFetchParams)

      setConfirmOpen(false)
      setDeletingNoteId(null)
    } else {
      console.error("DeletingNoteId not provided")
    }
  }
  const handleCloseConfirm = () => {
    setConfirmOpen(false)
    setDeletingNoteId(null)
  }

  const updateEntryModalHandler = async ({
    noteDate,
    noteParams,
  }: CreateEntryModalHandlerArgs) => {
    await dispatch(updateNote(noteDate))
      .unwrap()
      .then((originalPromiseResult) => {
        showUpdateSuccessAlert()
      })
      .catch((rejectedValueOrSerializedError) => {
        showUpdateErrorAlert(rejectedValueOrSerializedError.message)
      })
    await updateNoteList(dispatch, noteFetchParams)
  }

  const confirmDeleteModal = (
    <ConfirmationModal
      isOpen={isConfirmOpen}
      onClose={handleCloseConfirm}
      title="Підтвердити видалення"
      onConfirm={handleConfirmDelete}
    >
      Ви впевнені, що хочете видалити цей запис?
    </ConfirmationModal>
  )

  const viewModal = (
    <ViewModal
      isViewModalOpen={isViewModalOpen}
      selectedNote={selectedNote}
      shopList={shopList}
      handleCloseViewModal={handleCloseViewModal}
    />
  )

  const entryModal = (
    <EntryForm
      handler={updateEntryModalHandler}
      title="Редагування запису"
      submitTitle="Редагувати запис"
      isOpen={isEditFormOpen}
      onClose={handleCloseEditForm}
      note={selectedNote}
    />
  )

  return (
    <Fragment>
      <Table size="md">
        <TableHeader />
        <Tbody boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)">
          {(fetchStatus === LoadStatus.Loading ||
            fetchStatus === LoadStatus.Idle) && (
            <TableSkeleton rows={20} columns={11} />
          )}
          {fetchStatus === LoadStatus.Succeeded &&
            noteList.map((note: Note) => (
              <TableRow
                key={note.id}
                note={note}
                userInfo={userInfo}
                onEdit={() => handleEdit(note)}
                onView={() => handleView(note)}
                onDelete={() => handleDelete(note.id)}
              />
            ))}
          {fetchStatus === LoadStatus.Succeeded && noteList.length === 0 && (
            <Tr>
              <Td colSpan={10}>
                <Center>
                  <Text fontSize="lg" color="gray.500">
                    {selectionsHasTrueValues
                      ? "Немає записів із даним фільтруванням"
                      : "Немає записів"}
                  </Text>
                </Center>
              </Td>
            </Tr>
          )}
          {fetchStatus === LoadStatus.Failed && (
            <Tr>
              <Td colSpan={10} py="3" px="4">
                <Flex alignItems="center">
                  <Text>Помилка при завантаженні нотаток:</Text>
                  <Text color="red" ml={2}>
                    {fetchError}
                  </Text>
                </Flex>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {isEditFormOpen && entryModal}
      {confirmDeleteModal}
      {viewModal}
    </Fragment>
  )
}

export default PCTable
