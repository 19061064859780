import React from "react"
import NoteHeaderWrapper from "../components/NoteHeaderWrapper/NoteHeaderWrapper"
import {
  Flex,
  Text,
  IconButton,
  useColorModeValue,
  Tooltip,
  Skeleton,
} from "@chakra-ui/react"
import { ArrowBackIcon } from "@chakra-ui/icons"
import { useNavigate } from "react-router-dom"
import { generateHeaderUserIcon } from "../utils"
import { useUserInfo } from "../app/hooks"

const CommonHeader: React.FC<{ title: string }> = ({ title }) => {
  const navigate = useNavigate()
  const textColor = useColorModeValue("gray.800", "whiteAlpha.900")
  const hoverBgColor = useColorModeValue("yellow.100", "yellow.600")

  const goBack = () => navigate(-1)
  const userInfo = useUserInfo()

  return (
    <NoteHeaderWrapper>
      <Flex alignItems="center">
        <Tooltip label="Повернутися назад" hasArrow>
          <IconButton
            icon={<ArrowBackIcon />}
            aria-label="Назад"
            onClick={goBack}
            variant="ghost"
            colorScheme="yellow"
            _hover={{ bg: hoverBgColor, cursor: "pointer" }}
            boxShadow="sm"
          />
        </Tooltip>
        <Text fontSize="2xl" fontWeight="bold" color={textColor} ml={4}>
          {title}
        </Text>
        {userInfo.user ? (
          generateHeaderUserIcon(userInfo.user)
        ) : (
          <Skeleton ml={2} w={40} h="40px" />
        )}
      </Flex>
    </NoteHeaderWrapper>
  )
}

export default CommonHeader
