import { createSelector, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { getCurrentMonth, getCurrentYear } from "../../utils"

export interface SelectionsState {
  sellerId: string | undefined
  shopId: string | undefined
  selectedYear: number | undefined
  selectedMonth: number | undefined
}

const initialState: SelectionsState = {
  sellerId: undefined,
  shopId: undefined,
  selectedYear: getCurrentYear(),
  selectedMonth: getCurrentMonth(),
}

// selectionsSlice for main table selects
const selectionsSlice = createSlice({
  name: "selections",
  initialState,
  reducers: {
    setSellerId(state, action) {
      state.sellerId = action.payload
    },
    setShopId(state, action) {
      state.shopId = action.payload
    },
    setSelectedYear(state, action) {
      state.selectedYear = action.payload
    },
    setSelectedMonth(state, action) {
      state.selectedMonth = action.payload
    },
  },
})

export const { setSellerId, setShopId, setSelectedYear, setSelectedMonth } =
  selectionsSlice.actions

export const selectSelections = (state: RootState) => state.selections

export const selectSelectionsDetails = createSelector(
  selectSelections,
  (selections) => ({
    sellerId: selections.sellerId,
    shopId: selections.shopId,
    selectedYear: selections.selectedYear,
    selectedMonth: selections.selectedMonth,
  }),
)

export default selectionsSlice.reducer
