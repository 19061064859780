import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import type { RootState, AppDispatch } from "./store"

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useNotePageInfo = () => {
  return useAppSelector((state: RootState) => state.notes.pageInfo)
}

export const useNoteList = () => {
  return useAppSelector((state: RootState) => state.notes.notes)
}

export const useUserInfo = () => {
  return useAppSelector((state: RootState) => state.user)
}

export const useSelections = () => {
  return useAppSelector((state: RootState) => state.selections)
}

export const useAdditionalUserRights = () => {
  return useAppSelector((state: RootState) => state.additionalUserRights)
}

export const useLocationInfo = () => {
  return useAppSelector((state: RootState) => state.locationInfo)
}
