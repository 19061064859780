import React, { ReactNode } from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
} from "@chakra-ui/react"
import { ChakraSize } from "../../types/common"

interface CustomModalProps {
  isOpen: boolean
  onClose: () => void
  title: string
  children: ReactNode
  size?: ChakraSize
}

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  size = "xl",
}) => {
  return (
    <Modal
      size={size}
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="scale"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <Box
          zIndex="docked"
          boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)"
        >
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton position="absolute" right="8px" top="8px" />
        </Box>
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CustomModal
