import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import {
  useAdditionalUserRights,
  useAppDispatch,
  useUserInfo,
} from "../../../app/hooks"
import { deleteUserAccessRights } from "../additionalUserRightsAPI"
import {
  LoadStatus,
  RightItem,
  Seller,
  UserFromAvailableUserList,
} from "../../../types/common"
import { Text, Button, Flex, Center } from "@chakra-ui/react"
import { IoAccessibility } from "react-icons/io5"
import { mapFromArray, updateAccessRightList } from "../../../utils"
import UserRightsItem from "../components/UserRightsItem"
import AdditionalUserRightsForm from "../forms/AdditionalUserRightsForm"
import LoadingRow from "../../../components/LoadingRow/LoadingRow"
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal"
import AdditionalUserRightsFormEdit from "../forms/AdditionalUserRightsFormEdit"
import {
  showDeleteErrorAlert,
  showDeleteSuccessAlert,
} from "../../../common/apiCrudAlertHelper"

interface AdditionalUserRightsListProps {}

const AdditionalUserRightsList: React.FC<
  AdditionalUserRightsListProps
> = ({}) => {
  const dispatch = useAppDispatch()
  const { loadingStatus, rightsList, fetchError } = useAdditionalUserRights()
  const { user, status: userInfoStatus, error } = useUserInfo()

  const [isAdditionalAccessRightModalOpen, setAdditionalAccessRightModalOpen] =
    useState(false)
  const [isAccessRightsEditModalOpen, setAccessRightsEditModalOpen] =
    useState(false)

  const [shopIdEditingList, setShopIdEditingList] = useState<string[] | []>([])

  const [shopEditingUserId, setShopEditingUserId] = useState<string | null>(
    null,
  )
  const [editingUserName, setEditingUserName] = useState<string | null>()

  const [isConfirmOpen, setConfirmOpen] = useState(false)
  const [accessRightDeletingId, setAccessRightDeletingId] = useState<
    string | null
  >(null)

  useEffect(() => {
    if (user?.userId) {
      updateAccessRightList(dispatch)
    }
  }, [dispatch, user?.userId])

  const usersMap = useMemo(() => {
    return mapFromArray(user?.availableUserList || [], "ID")
  }, [user?.availableUserList])

  const shopsMap = useMemo(() => {
    return mapFromArray(user?.availableShopList || [], "id")
  }, [user?.availableShopList])

  const findUserInUserMap = (
    userId: string,
  ): UserFromAvailableUserList | Seller => {
    return usersMap[userId]
  }

  const findShopsInShopsMap = (shop_id_list: string[]): string[] => {
    const matchedShops = shop_id_list.map((id) => {
      const shop = shopsMap[id]
      return shop ? `${shop.firstName} ${shop.lastName}` : "Невідомий магазин"
    })

    return matchedShops || "Магазини не знайдені"
  }

  const handleAdditionalAccessRightModalOpen = () =>
    setAdditionalAccessRightModalOpen(true)
  const handleAdditionalAccessRightModalClose = () =>
    setAdditionalAccessRightModalOpen(false)
  const handleAccessRightsModalClose = () => setAccessRightsEditModalOpen(false)

  const handleCloseConfirm = () => {
    setConfirmOpen(false)
    setAccessRightDeletingId(null)
  }

  const handleEdit = useCallback(
    (userId: string, userSelectedShoList: string[], userName: string) => {
      setAccessRightsEditModalOpen(true)
      setShopIdEditingList(userSelectedShoList)
      setShopEditingUserId(userId)
      setEditingUserName(userName)
    },
    [],
  )

  const handleDelete = useCallback((userId: string) => {
    setAccessRightDeletingId(userId)
    setConfirmOpen(true)
  }, [])

  const handleConfirmDelete = async () => {
    if (accessRightDeletingId) {
      await dispatch(deleteUserAccessRights(parseFloat(accessRightDeletingId)))
        .unwrap()
        .then((originalPromiseResult) => {
          showDeleteSuccessAlert()
        })
        .catch((rejectedValueOrSerializedError) => {
          showDeleteErrorAlert(rejectedValueOrSerializedError.message)
        })

      await updateAccessRightList(dispatch)
    }
    handleCloseConfirm()
  }

  const renderFetchError = () => {
    if (fetchError) {
      return (
        <Center my={10}>
          <Text fontSize="lg" color="red.500">
            Помилка при завантаженні додаткових прав користувачів: {fetchError}
          </Text>
        </Center>
      )
    }
    return null
  }

  const confirmDeleteModal = (
    <ConfirmationModal
      isOpen={isConfirmOpen}
      onClose={handleCloseConfirm}
      title="Підтвердити видалення"
      onConfirm={handleConfirmDelete}
    >
      Ви впевнені, що хочете видалити цей запис?
    </ConfirmationModal>
  )

  const listHeaderSectionContent = (
    <Flex justifyContent="space-between" alignItems="center" mb={4}>
      <Text as={"b"} fontSize="2xl">
        Додаткові права користувачів
      </Text>
      <Button
        onClick={handleAdditionalAccessRightModalOpen}
        leftIcon={<IoAccessibility />}
        colorScheme="yellow"
        size="md"
      >
        Налаштувати права
      </Button>
    </Flex>
  )

  const renderRightsItemList = () => {
    if (loadingStatus === LoadStatus.Succeeded && !rightsList.length) {
      return (
        <Center my={10}>
          <Text fontSize="lg" color="gray.600">
            Немає доступних прав для відображення.
          </Text>
        </Center>
      )
    }

    if (
      loadingStatus === LoadStatus.Succeeded &&
      userInfoStatus === LoadStatus.Succeeded
    ) {
      return rightsList.map((item: RightItem) => {
        return (
          <UserRightsItem
            key={item.user_id}
            user={findUserInUserMap(item.user_id)}
            shop_id_list={findShopsInShopsMap(item.shop_id_list)}
            onEdit={() =>
              handleEdit(
                item.user_id,
                item.shop_id_list,
                findUserInUserMap(item.user_id).FullName,
              )
            }
            onDelete={() => handleDelete(item.user_id)}
          />
        )
      })
    }
    if (
      loadingStatus === LoadStatus.Loading ||
      loadingStatus === LoadStatus.Idle
    ) {
      return <LoadingRow />
    }
  }

  return (
    <Fragment>
      {listHeaderSectionContent}
      {renderFetchError()}
      {renderRightsItemList()}
      {confirmDeleteModal}
      <AdditionalUserRightsForm
        isOpen={isAdditionalAccessRightModalOpen}
        onClose={handleAdditionalAccessRightModalClose}
      />
      <AdditionalUserRightsFormEdit
        isOpen={isAccessRightsEditModalOpen}
        onClose={handleAccessRightsModalClose}
        shopList={shopIdEditingList}
        userId={shopEditingUserId}
        userName={editingUserName}
      />
    </Fragment>
  )
}

export default AdditionalUserRightsList
