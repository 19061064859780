import React from "react"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import { chakra, Skeleton } from "@chakra-ui/react"
import { createSelectOptions } from "../../utils"
import { CustomSelectItem } from "../../types/common"

const ChakraReactSelect = chakra(Select)

interface CustomSelectProps {
  placeholder: string
  onChange: (selectedOption: any) => void
  itemList: CustomSelectItem[]
  w?: number
  mr?: number
  value?: any
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  placeholder,
  onChange,
  itemList,
  w = 210,
  mr = 0,
  value,
}) => {
  if (!itemList.length) {
    return <Skeleton mr={2} w={w} h="40px" />
  }
  const animatedComponents = makeAnimated()

  const customStyles = {
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  }

  const options = createSelectOptions(itemList)

  const selectedOption =
    options.find((option) => option.value === value) || null

  return (
    <ChakraReactSelect
      components={animatedComponents}
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      styles={customStyles}
      minWidth={w}
      mr={mr}
      menuPortalTarget={document.body}
      sx={{
        ".chakra-react-select__control": {
          border: "none",
          borderRadius: "md",
        },
        ".chakra-react-select__menu": {
          boxShadow: "lg",
        },
      }}
      value={selectedOption}
    />
  )
}

export default CustomSelect
