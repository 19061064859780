import React, { useEffect } from "react"
import { Flex, Text, keyframes, Box } from "@chakra-ui/react"

interface LoadingOverlayProps {
  isOpen: boolean
  error?: string | null
}

const blink = keyframes`
  50% { opacity: 0; }
`

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ isOpen, error }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
    }
    return () => {
      document.body.style.overflow = "unset"
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <Flex
      justify="center"
      align="center"
      position="fixed"
      top={0}
      right={0}
      bottom={0}
      left={0}
      bg="blackAlpha.800"
      zIndex="modal"
    >
      {error ? (
        <Text color="red.500" fontSize="xl" fontWeight="bold">
          Помилка: {error}
        </Text>
      ) : (
        <Text color="white" fontSize="xl" fontWeight="bold">
          Ініціалізація працівника
          <Box as="span" animation={`${blink} 1s linear infinite`}>
            .
          </Box>
          <Box
            as="span"
            animation={`${blink} 1s linear infinite`}
            style={{ animationDelay: "0.2s" }}
          >
            .
          </Box>
          <Box
            as="span"
            animation={`${blink} 1s linear infinite`}
            style={{ animationDelay: "0.4s" }}
          >
            .
          </Box>
        </Text>
      )}
    </Flex>
  )
}

export default LoadingOverlay
