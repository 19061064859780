import axios from "axios"

const api_key = import.meta.env.VITE_APP_API_KEY

const baseURL =
  import.meta.env.MODE === "development"
    ? "http://localhost:3000/sellers-statistic/"
    : import.meta.env.VITE_API_BASE_URL

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${api_key}`,
  },
})

export default axiosInstance
