import React from "react"
import { Alert, AlertIcon } from "@chakra-ui/react"

interface AlertProps {
  status: "info" | "warning"
  message: string
}

const AlertMessage: React.FC<AlertProps> = ({ status, message }) => {
  return (
    <Alert status={status}>
      <AlertIcon />
      {message}
    </Alert>
  )
}

export default AlertMessage
