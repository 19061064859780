import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import notesReducer from "../features/note/noteSlice"
import userReducer from "../features/user/userSlice"
import tableSelectionReducer from "../features/note/selectionSlice"
import additionalUserRightsReducer from "../features/additionalUserRights/additionalUserRightsSlice"
import locationInfoReducer from "../features/locationInfo/locationInfoSlice"

export const store = configureStore({
  reducer: {
    notes: notesReducer,
    user: userReducer,
    selections: tableSelectionReducer,
    additionalUserRights: additionalUserRightsReducer,
    locationInfo: locationInfoReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
