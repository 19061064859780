import { BX24 } from "bx24"
import { BXUser, ParamsType } from "./types/user"
import { getQueryString } from "./utils"

const bx24 = new BX24(window, parent)

const BX24API = new (class BX24API {
  baseUrl: string
  session?: { ACCESS_TOKEN?: string }

  constructor() {
    void this.auth()
    const urlParams = new URLSearchParams(window.location.search)
    this.baseUrl = `https://${urlParams.get("DOMAIN")}`
  }

  async auth() {
    if (this.session?.ACCESS_TOKEN) return this.session
    this.session = await bx24.getAuth()
    return this.session
  }

  async callMethod(name: string, params: ParamsType = {}) {
    await this.auth()
    params.auth = this.session?.ACCESS_TOKEN

    const queryString = getQueryString(params)

    const result = await fetch(this.baseUrl + `/rest/${name}?`, {
      method: "POST",
      body: queryString,
    })

    return await result.json()
  }

  async getAll(name: string, params: ParamsType = {}) {
    const response = await this.callMethod(name, params)

    if (response.result.length < response.next) {
      return response
    }

    for (let i = 1; i < Math.ceil(response.total / response.next); i++) {
      params.start = i * response.next
      const tmpResponse = await this.callMethod(name, params)
      response.result = [...response.result, ...tmpResponse.result]
    }
    return response
  }
})()

export const getUserFromBX24 = async (): Promise<BXUser | undefined> => {
  try {
    const response = await BX24API.callMethod("user.current", {})

    return {
      id: response.result.ID,
      name: `${response.result.NAME} ${response.result.LAST_NAME}`,
    }
  } catch (error) {
    console.error("Failed to get user from BX24:", error)
  }
}
