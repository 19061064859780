import React from "react"
import { Box } from "@chakra-ui/react"
import {
  useAppDispatch,
  useNotePageInfo,
  useSelections,
  useUserInfo,
} from "../../../app/hooks"
import Pagination from "../../../components/Pagination/Pagination"
import { fetchNotes } from "../noteAPI"
import ResponsiveTableWrapper from "./ResponsiveTableWrapper"
import { createNoteDispatchDtoIn } from "../../../utils"

const NoteTable = () => {
  const dispatch = useAppDispatch()

  const notePageInfo = useNotePageInfo()
  const userInfo = useUserInfo()
  const selections = useSelections()

  const totalPages = notePageInfo ? Math.ceil(notePageInfo.total / 20) : 0
  const currentPage = notePageInfo?.pageIndex ? notePageInfo.pageIndex + 1 : 1

  const onPageChange = (pageNumber: number) => {
    const noteParams = createNoteDispatchDtoIn(
      userInfo,
      selections,
      notePageInfo,
      pageNumber,
    )
    dispatch(fetchNotes(noteParams))
  }

  return (
    <Box p="5" my="4">
      <ResponsiveTableWrapper />
      {Boolean(totalPages) && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      )}
    </Box>
  )
}

export default NoteTable
