import React, { useState } from "react"
import {
  Badge,
  Box,
  Button,
  Flex,
  Text,
  VStack,
  Tooltip as TooltipC,
} from "@chakra-ui/react"
import { FaChartLine, FaChartBar } from "react-icons/fa"
import { ChartProps } from "../../types/seller-chart"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts"

const ChartComponent: React.FC<ChartProps> = ({
  data,
  dataKey,
  color,
  title,
  labelKey,
}) => {
  const [chartType, setChartType] = useState<"line" | "bar">("line")
  const total = data.reduce((acc, cur) => acc + Number(cur[dataKey]), 0)

  const toggleChartType = () => {
    setChartType(chartType === "line" ? "bar" : "line")
  }

  const ChartIcon = chartType === "line" ? FaChartBar : FaChartLine

  return (
    <Box
      p="4"
      bg="white"
      boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
      borderRadius="md"
      mb="5"
    >
      <Flex justifyContent="space-between" alignItems="center" mb="4">
        <Text fontSize="xl" fontWeight="bold">
          {title}
        </Text>
        <TooltipC
          label={`Перемкнути на ${chartType === "line" ? "стовпці" : "лінію"}`}
        >
          <Button onClick={toggleChartType} variant="ghost">
            <ChartIcon size="20px" />
          </Button>
        </TooltipC>
      </Flex>

      <ResponsiveContainer width="100%" height={300}>
        {chartType === "line" ? (
          <LineChart
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="createdate" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey={dataKey}
              stroke={color}
              activeDot={{ r: 8 }}
              name={labelKey || dataKey}
            />
          </LineChart>
        ) : (
          <BarChart
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="createdate" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey={dataKey} fill={color} name={labelKey || dataKey} />
          </BarChart>
        )}
      </ResponsiveContainer>
      <VStack mt="4" align="stretch">
        <Text>
          Загальне значення: <Badge fontSize="0.9em">{total.toFixed(2)}</Badge>
        </Text>
      </VStack>
    </Box>
  )
}

export default ChartComponent
