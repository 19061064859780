import React from "react"
import { Box } from "@chakra-ui/react"
import "./LoadingRow.css"
interface CustomLoadingRowProps {
  w?: number
}

const LoadingRow: React.FC<CustomLoadingRowProps> = ({ w }) => {
  return (
    <Box w={w} className="loading-dots">
      <span></span>
      <span></span>
      <span></span>
    </Box>
  )
}

export default LoadingRow
