import React, { Fragment, useEffect, useState } from "react"
import { AddIcon, SettingsIcon } from "@chakra-ui/icons"
import { Button, Flex, IconButton, Skeleton, Tooltip } from "@chakra-ui/react"
import CustomSelect from "../../../components/CustomSelect/CustomSelect"
import MonthYearPicker from "../../../components/MonthYearPicker/MonthYearPicker"
import EntryForm from "../forms/EntryForm"
import { LoadStatus } from "../../../types/common"
import { addNote, fetchNotes } from "../noteAPI"
import { useAppDispatch, useUserInfo } from "../../../app/hooks"
import { useSelector } from "react-redux"
import {
  selectSelectionsDetails,
  setSelectedMonth,
  setSelectedYear,
  setSellerId,
  setShopId,
} from "../selectionSlice"
import { FcClearFilters } from "react-icons/fc"
import {
  generateHeaderUserIcon,
  getSellerIds,
  getShopIds,
  updateNoteList,
} from "../../../utils"
import { CreateEntryModalHandlerArgs } from "../../../types/note"
import { Link } from "react-router-dom"
import NoteHeaderWrapper from "../../../components/NoteHeaderWrapper/NoteHeaderWrapper"
import { showToastSuccess } from "../../../common/showAlert"
import {
  showCreateErrorAlert,
  showCreateSuccessAlert,
} from "../../../common/apiCrudAlertHelper"
import ReportForm from "../../report/forms/ReportForm"
import { TfiBarChart } from "react-icons/tfi"

interface HeaderSectionProps {}

const NoteHeader: React.FC<HeaderSectionProps> = () => {
  const dispatch = useAppDispatch()

  const userInfo = useUserInfo()
  const selections = useSelector(selectSelectionsDetails)

  const [isEntryModalOpen, setEntryModalOpen] = useState(false)

  const { sellerId, shopId, selectedYear, selectedMonth } = selections
  const [isReportModalOpen, setIsReportModalOpen] = useState(false)

  const sellerList = userInfo.user?.availableUserList || []
  const shopList = userInfo.user?.availableShopList || []

  useEffect(() => {
    if (userInfo.status === LoadStatus.Succeeded) {
      const initialShopIds = getShopIds(userInfo)
      const initialSellerIds = getSellerIds(userInfo)

      const fetchNotesDtoIn = {
        shopIdList: shopId ? [shopId] : initialShopIds,
        responsibleIdList: sellerId ? [sellerId] : initialSellerIds,
        selectedYear: selectedYear,
        selectedMonth: selectedMonth,
        pageInfo: { pageIndex: "0", pageSize: "20" },
      }

      dispatch(fetchNotes(fetchNotesDtoIn))
    }
  }, [sellerId, shopId, selectedYear, selectedMonth, dispatch])

  const handleSellerChangeSelect = (selectedOption: any) => {
    dispatch(setSellerId(selectedOption.value))
  }

  const handleShopChangeSelect = (selectedOption: any) => {
    dispatch(setShopId(selectedOption.value))
  }

  const handleYearChange = (year: string) => {
    dispatch(setSelectedYear(year))
  }

  const handleMonthChange = (month: string) => {
    dispatch(setSelectedMonth(month))
  }

  const handleEntryModalOpen = () => setEntryModalOpen(true)
  const handleEntryModalClose = () => setEntryModalOpen(false)
  const handleReportModalOpen = () => setIsReportModalOpen(true)
  const handleReportModalClose = () => setIsReportModalOpen(false)

  const createEntryModalHandler = async ({
    noteDate,
    noteParams,
  }: CreateEntryModalHandlerArgs) => {
    await dispatch(addNote(noteDate))
      .unwrap()
      .then((originalPromiseResult) => {
        showCreateSuccessAlert()
      })
      .catch((rejectedValueOrSerializedError) => {
        showCreateErrorAlert(rejectedValueOrSerializedError.message)
      })
    await updateNoteList(dispatch, noteParams)
  }

  const handleResetSelections = () => {
    dispatch(setSellerId(null))
    dispatch(setShopId(null))
    dispatch(setSelectedMonth(null))
    dispatch(setSelectedYear(null))
    showToastSuccess({ message: "Фільтри скинуто" })
  }

  const renderedUserInfo = userInfo.user ? (
    generateHeaderUserIcon(userInfo.user)
  ) : (
    <Skeleton mr={2} w={40} h="40px" />
  )

  const leftContent = (
    <Fragment>
      <CustomSelect
        mr={2}
        w={200}
        placeholder="Вибрати продавця"
        onChange={handleSellerChangeSelect}
        itemList={sellerList}
        value={sellerId}
      />
      <CustomSelect
        mr={2}
        w={195}
        placeholder="Вибрати магазин"
        onChange={handleShopChangeSelect}
        itemList={shopList}
        value={shopId}
      />
      <MonthYearPicker
        status={userInfo.status}
        onMonthChange={handleMonthChange}
        onYearChange={handleYearChange}
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
      />

      <Tooltip label="Скинути фільтра" hasArrow>
        <Button
          isDisabled={userInfo.status !== "succeeded"}
          leftIcon={<FcClearFilters />}
          colorScheme="red"
          variant="ghost"
          onClick={handleResetSelections}
          ml={2}
        />
      </Tooltip>
    </Fragment>
  )

  const rightContent = (
    <Flex>
      <Button
        onClick={handleReportModalOpen}
        colorScheme="yellow"
        variant="outline"
        mr="2"
      >
        Звіт
      </Button>
      <Tooltip hasArrow label="Графіки">
        <Link to="/seller-chart">
          <IconButton
            aria-label="Графіки"
            icon={<TfiBarChart />}
            colorScheme="yellow"
            variant="outline"
            mr="2"
          />
        </Link>
      </Tooltip>
      <Button
        onClick={handleEntryModalOpen}
        leftIcon={<AddIcon />}
        colorScheme="yellow"
        variant="solid"
        mr="2"
      >
        Новий запис
      </Button>
      {renderedUserInfo}
      {userInfo.user && userInfo.user.isAdmin && (
        <Tooltip hasArrow label="Налаштування">
          <Link to="/settings">
            <IconButton
              aria-label="Налаштування"
              icon={<SettingsIcon />}
              colorScheme="yellow"
              variant="ghost"
            />
          </Link>
        </Tooltip>
      )}
    </Flex>
  )

  return (
    <Fragment>
      <NoteHeaderWrapper
        leftContent={leftContent}
        rightContent={rightContent}
      />
      <EntryForm
        handler={createEntryModalHandler}
        sellerList={sellerList}
        shopList={shopList}
        title="Новий запис"
        submitTitle="Створити запис"
        isOpen={isEntryModalOpen}
        onClose={handleEntryModalClose}
      />

      <ReportForm isOpen={isReportModalOpen} onClose={handleReportModalClose} />
    </Fragment>
  )
}

export default NoteHeader
