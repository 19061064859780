import React, { Fragment, useEffect } from "react"
import { AppSelectInputWithSearch } from "../../../components/inputs/AppSelectInputWithSearch"
import { cfg } from "../../../cfg"
import {
  createMonthOptions,
  createSelectOptions,
  createYearOptions,
  getMonthList,
  getYearList,
} from "../../../utils"
import { mapValues } from "lodash"
import { useUserInfo } from "../../../app/hooks"
import { useForm } from "react-hook-form"
import CustomForm from "../../../components/CustomForm/CustomForm"
import { Button } from "@chakra-ui/react"
import { createReport } from "../../../api/api"
import { showToastError, showToastSuccess } from "../../../common/showAlert"
import { AxiosError } from "axios"
import { ReportFormDefaultValues } from "../../../types/report"
import { Seller } from "../../../types/common"

interface ReportForm {
  isOpen: boolean
  onClose: () => void
}

const ReportForm: React.FC<ReportForm> = ({ isOpen, onClose }) => {
  const userInfo = useUserInfo()

  const {
    handleSubmit: useFormSubmit,
    control,
    formState: { errors, isSubmitting },
    watch,
    reset,
  } = useForm<ReportFormDefaultValues>({
    defaultValues: cfg.reportForm.defaultValues,
  })

  const watchedValues = watch()

  useEffect(() => {
    // auto select
    if (isOpen) {
      const currentYear: number = new Date().getFullYear()
      const currentMonth: number = new Date().getMonth() + 1
      const availableUserList: Seller[] = userInfo.user?.availableUserList || []

      const defaultValues: ReportFormDefaultValues = {
        ...cfg.reportForm.defaultValues,
        year: currentYear.toString(),
        month: currentMonth.toString(),
      }

      if (availableUserList.length === 1) {
        defaultValues.sellerId = availableUserList[0].ID.toString()
      }

      reset(defaultValues)
    }
  }, [isOpen, userInfo?.user?.availableUserList, reset])

  const sellerOptions = createSelectOptions(
    userInfo.user?.availableUserList || [],
  )
  const monthOptions = createMonthOptions(getMonthList())
  const yearOptions = createYearOptions(getYearList())

  const handleSubmitReportForm = async (data: any) => {
    data = mapValues(data, (value) => {
      return typeof value === "number" ? value.toString() : value
    })

    try {
      await createReport(data)
      showToastSuccess({ message: "Звіт успішно створено." })
    } catch (error) {
      if (error instanceof AxiosError) {
        const message = error.response?.data.message || cfg.unexpectedError
        showToastError({ message })
      } else {
        showToastError({ message: cfg.unexpectedError })
      }
    }
  }

  const footer = (
    <Button
      isLoading={isSubmitting}
      type="submit"
      colorScheme="yellow"
      width="100%"
    >
      Cформувати звіт
    </Button>
  )

  return (
    <Fragment>
      <CustomForm
        handleSubmit={handleSubmitReportForm}
        useFormSubmit={useFormSubmit}
        title="Звiт"
        isOpen={isOpen}
        onClose={() => {
          onClose()
          reset(cfg.reportForm.defaultValues)
        }}
        modalFooter={footer}
        modalSize="sm"
      >
        <AppSelectInputWithSearch
          w={"100%"}
          control={control}
          value={parseFloat(watchedValues.sellerId)}
          name={cfg.reportForm.seller.name}
          label={cfg.reportForm.seller.label}
          helperText={cfg.reportForm.seller.helperText}
          placeholder={cfg.reportForm.seller.placeholder}
          options={sellerOptions}
          rules={{ required: cfg.useFormRules.requiredMsg }}
          errors={errors}
        />
        <AppSelectInputWithSearch
          w={"100%"}
          control={control}
          value={parseFloat(watchedValues.month)}
          name={cfg.reportForm.month.name}
          label={cfg.reportForm.month.label}
          helperText={cfg.reportForm.month.helperText}
          placeholder={cfg.reportForm.month.placeholder}
          options={monthOptions}
          rules={{ required: cfg.useFormRules.requiredMsg }}
          errors={errors}
        />
        <AppSelectInputWithSearch
          w={"100%"}
          control={control}
          value={parseFloat(watchedValues.year)}
          name={cfg.reportForm.year.name}
          label={cfg.reportForm.year.label}
          helperText={cfg.reportForm.year.helperText}
          placeholder={cfg.reportForm.year.placeholder}
          options={yearOptions}
          rules={{ required: cfg.useFormRules.requiredMsg }}
          errors={errors}
        />
      </CustomForm>
    </Fragment>
  )
}

export default ReportForm
