import {
  CustomSelectItem,
  Department,
  PageInfo,
  Seller,
  Shop,
  StyledBoxProps,
  WatchedValues,
} from "./types/common"
import { UserState } from "./features/user/userSlice"
import toastr from "toastr"
import styled from "@emotion/styled"
import { SelectionsState } from "./features/note/selectionSlice"
import { cfg } from "./cfg"
import { Note, NoteDispatchDto } from "./types/note"
import { fetchNotes } from "./features/note/noteAPI"
import { AppDispatch } from "./app/store"
import { Avatar, AvatarBadge, Flex, Text } from "@chakra-ui/react"
import React from "react"
import { User } from "./types/user"
import axios, { AxiosResponse } from "axios"
import { AsyncThunkPayloadCreator } from "@reduxjs/toolkit"
import {
  showUpdateErrorAlert,
  showUpdateSuccessAlert,
} from "./common/apiCrudAlertHelper"
import { showToastError } from "./common/showAlert"
import { fetchAdditionalUserRights } from "./features/additionalUserRights/additionalUserRightsAPI"

toastr.options = {
  closeButton: true,
  newestOnTop: true,
  progressBar: true,
}

const formatOptionLabel = (item: CustomSelectItem) => {
  if ("firstName" in item && "lastName" in item) {
    // об'єкт Shop
    return `${item.firstName} ${item.lastName}`
  } else if ("FullName" in item) {
    // об'єкт Seller
    return item.FullName
  }
}

const createSelectOptions = (itemList: CustomSelectItem[]) => {
  return itemList.map((item) => ({
    value: "firstName" in item ? +item.id : +item.ID,
    label: formatOptionLabel(item),
  }))
}

const createMonthOptions = (months: string[]) => {
  return months.map((month, index) => ({
    value: index + 1,
    label: month,
  }))
}

const createYearOptions = (years: number[]) => {
  return years.map((year) => ({
    value: year,
    label: year.toString(),
  }))
}

const getShopIds = (user: UserState) => {
  return user.user?.availableShopList.map((shop: Shop) => shop.id) || []
}

const getSellerIds = (user: UserState) => {
  return user.user?.availableUserList.map((seller: Seller) => seller.ID) || []
}

const findSelectedShop = (
  shopList: Shop[],
  shopId: string = "",
): Shop | undefined => {
  return shopList.find((shop) => shop.id.toString() === shopId.toString())
}

const findSelectedSeller = (
  sellerList: Seller[],
  sellerId: string,
): Seller | undefined => {
  return sellerList.find(
    (seller) => seller.ID.toString() === sellerId.toString(),
  )
}

const calculatePercentageFromMargin = (
  margin: string | undefined,
  marginPercentage: string | number | undefined,
) => {
  if (!margin || !marginPercentage) return 0
  return ((+margin / 100) * +marginPercentage).toFixed(2)
}

const calculateSalaryPerDay = (
  percentage: number | undefined,
  rate: number | undefined,
  margin: string | undefined,
) => {
  if (!margin || !percentage || !rate) return 0
  return ((parseFloat(margin) / 100) * percentage + rate).toFixed()
}

const calculateCup = (watchedValues: Partial<WatchedValues>) => {
  const { checksToAnUnknownPerson, totalSeverityOfChecks } = watchedValues
  if (!checksToAnUnknownPerson || !totalSeverityOfChecks) return 0
  return +((+checksToAnUnknownPerson / +totalSeverityOfChecks) * 100).toFixed()
}

const calculatePackagesPercent = (watchedValues: Partial<WatchedValues>) => {
  const { packages, totalSeverityOfChecks } = watchedValues
  if (!packages || !totalSeverityOfChecks) return 0
  return +((+packages / +totalSeverityOfChecks) * 100).toFixed()
}

const calculateAllIncome = (watchedValues: Partial<WatchedValues>) => {
  const { getWithCard, getInCash } = watchedValues
  const cardIncome = getWithCard ? parseFloat(getWithCard) : 0
  const cashIncome = getInCash ? parseFloat(getInCash) : 0
  return +(cardIncome + cashIncome).toFixed(2)
}

const calculateSalaryPerDayForTableRaw = (note: Note) => {
  return (
    (parseFloat(note.margin) / 100) * parseFloat(note.percentage) +
    note.rate
  ).toFixed(2)
}

const createNoteDispatchDtoIn = (
  userInfo: UserState,
  selections: SelectionsState,
  notePageInfo: PageInfo | null,
  pageNumber = 1,
): NoteDispatchDto => {
  const initialShopIds =
    userInfo.user?.availableShopList.map((shop) => shop.id) || []
  const initialSellerIds =
    userInfo.user?.availableUserList.map((seller) => seller.ID) || []

  const pageInfo = notePageInfo
    ? {
        pageIndex: (pageNumber - 1).toString(),
        pageSize: "20",
      }
    : undefined

  return {
    pageInfo,
    shopIdList: selections.shopId ? [selections.shopId] : initialShopIds,
    responsibleIdList: selections.sellerId
      ? [selections.sellerId]
      : initialSellerIds,
    selectedYear: selections.selectedYear,
    selectedMonth: selections.selectedMonth,
  }
}

const StyledBox = styled.div<StyledBoxProps>`
  position: relative;
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("${(props: any) => props.iconImage}");
    background-position: bottom 30px right;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.05;
    z-index: -1;
  }

  & > * {
    position: relative;
    z-index: 1;
  }
`

const getDefaultFormValues = (note: Note | undefined) => {
  if (note) {
    return {
      [cfg.entryForm.data.name]: new Date(note.date),
      [cfg.entryForm.shop.name]: note.shopid.toString(),
      [cfg.entryForm.seller.name]: note.responsibleid.toString(),
      [cfg.entryForm.getWithCard.name]: note.cardreceipts.toString(),
      [cfg.entryForm.getInCash.name]: note.cashreceipts.toString(),
      [cfg.entryForm.margin.name]: note.margin.toString(),
      [cfg.entryForm.totalSeverityOfChecks.name]: note.totalchecks.toString(),
      [cfg.entryForm.checksToAnUnknownPerson.name]: note.cup.toString(),
      [cfg.entryForm.packages.name]: note.packages.toString(),
    }
  } else {
    return cfg.entryForm.defaultValues
  }
}

const getQueryString = (params = {}) => {
  let queryString = ""
  for (let elem in params) {
    // @ts-ignore
    if (typeof params[elem] === "object") {
      // @ts-ignore
      for (let subElem in params[elem]) {
        // @ts-ignore
        queryString += `${elem}[${subElem}]=${params[elem][subElem]}&`
      }
    } else {
      // @ts-ignore
      queryString += `${elem}=${params[elem]}&`
    }
  }

  return queryString
}

const isEditBtnDisabled = (note: Note, userInfo: UserState): boolean => {
  const isSellerEmployee = !!userInfo.user?.isSellerEmployee
  const isAdmin = !!userInfo.user?.isAdmin

  if (isAdmin && !isSellerEmployee) {
    return false
  }

  const threeDaysAgo = new Date()
  threeDaysAgo.setDate(threeDaysAgo.getDate() - 3)

  const noteDate = new Date(note.createdate)

  return noteDate <= threeDaysAgo
}

const determineStyledBoxProps = (
  firstName: string,
  iconImageVandal: string,
  iconImageVH: string,
): { department: Department | null; iconImage?: string } => {
  if (firstName === "") {
    return { department: null }
  }

  const isVapeHub = firstName.includes("VH")
  const department = isVapeHub ? Department.VapeHub : Department.Vandal
  const iconImage = isVapeHub ? iconImageVH : iconImageVandal

  return { department, iconImage }
}

const mapFromArray = <T extends Record<string, any>>(
  array: T[],
  key: keyof T,
): Record<string, T> => {
  return array.reduce(
    (acc, current) => {
      const mapKey = String(current[key])
      acc[mapKey] = current
      return acc
    },
    {} as Record<string, T>,
  )
}

const updateNoteList = async (
  dispatch: AppDispatch,
  noteFetchParams: NoteDispatchDto,
) => {
  await dispatch(fetchNotes(noteFetchParams))
    .unwrap()
    .then((originalPromiseResult) => {})
    .catch((rejectedValueOrSerializedError) => {
      showToastError({
        message: `Помилка при отриманні записів: ${
          rejectedValueOrSerializedError.message || "500"
        }`,
      })
    })
}

const generateHeaderUserIcon: React.FC<User> = (userInfo) => {
  const fullName =
    userInfo.name && userInfo.lastName
      ? `${userInfo.name} ${userInfo.lastName}`
      : userInfo.name

  if (!fullName && !userInfo.personalPhoto) return null

  return (
    <Flex justifyContent="flex-start" alignItems="center">
      {userInfo.personalPhoto && (
        <Avatar ml={7} size="sm" name={fullName} src={userInfo.personalPhoto}>
          <AvatarBadge borderColor="papayawhip" bg="green" boxSize="1.25em" />
        </Avatar>
      )}

      <Text ml={2} mr={2} fontSize="lg">
        {fullName}
      </Text>
    </Flex>
  )
}

export function handleAsyncThunkError<T>(
  payloadCreator: AsyncThunkPayloadCreator<any, T, { rejectValue: any }>,
): AsyncThunkPayloadCreator<any, T, { rejectValue: any }> {
  return async (args, thunkAPI) => {
    try {
      return await payloadCreator(args, thunkAPI)
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue(
          error.response?.data || "An error occurred with the request",
        )
      } else if (error instanceof Error) {
        return thunkAPI.rejectWithValue(error.message)
      } else {
        return thunkAPI.rejectWithValue("An unexpected error occurred")
      }
    }
  }
}

const updateAccessRightList = async (dispatch: AppDispatch) => {
  await dispatch(fetchAdditionalUserRights())
    .unwrap()
    .then((originalPromiseResult) => {})
    .catch((rejectedValueOrSerializedError) => {
      showToastError({
        message: `Помилка при отриманні записів: ${
          rejectedValueOrSerializedError.message || "500"
        }`,
      })
    })
}

const validate3DaysRangeForDatePicker = (value: Date) => {
  const selectedDate = value ? new Date(value) : new Date()
  const today = new Date()
  const twoDaysAgo = new Date(today)
  twoDaysAgo.setDate(twoDaysAgo.getDate() - 2)

  today.setHours(23, 59, 59, 999) // Кінець поточного дня
  twoDaysAgo.setHours(0, 0, 0, 0) // Початок дня два дні тому

  return (
    (selectedDate >= twoDaysAgo && selectedDate <= today) ||
    "Валідні дати: сьогодні та два дні до."
  )
}
const getCurrentMonth = (): number => new Date().getMonth() + 1
const getCurrentYear = (): number => new Date().getFullYear()

const getYearList = () => {
  return Array.from(
    { length: 2 },
    (_, index) => new Date().getFullYear() - index,
  )
}

const getMonthList = () => {
  return [
    "Січень",
    "Лютий",
    "Березень",
    "Квітень",
    "Травень",
    "Червень",
    "Липень",
    "Серпень",
    "Вересень",
    "Жовтень",
    "Листопад",
    "Грудень",
  ]
}

const getMonthListForCharts = () => {
  return [
    "січ.",
    "лют.",
    "бер.",
    "квіт.",
    "трав.",
    "черв.",
    "лип.",
    "серп.",
    "вер.",
    "жовт.",
    "лист.",
    "груд.",
  ]
}

const formatDateForChart = (dateString: string): string => {
  const date = new Date(dateString)
  const day = date.getDate()
  const months = getMonthListForCharts()
  const month = months[date.getMonth()]

  return `${day} ${month}`
}

export {
  formatOptionLabel,
  createSelectOptions,
  getShopIds,
  getSellerIds,
  calculateSalaryPerDay,
  calculateCup,
  calculatePackagesPercent,
  calculateAllIncome,
  findSelectedShop,
  findSelectedSeller,
  createNoteDispatchDtoIn,
  StyledBox,
  getDefaultFormValues,
  calculatePercentageFromMargin,
  getQueryString,
  isEditBtnDisabled,
  determineStyledBoxProps,
  getCurrentMonth,
  getCurrentYear,
  mapFromArray,
  updateNoteList,
  generateHeaderUserIcon,
  updateAccessRightList,
  calculateSalaryPerDayForTableRaw,
  validate3DaysRangeForDatePicker,
  getYearList,
  getMonthList,
  createMonthOptions,
  createYearOptions,
  getMonthListForCharts,
  formatDateForChart,
}
