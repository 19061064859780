import React from "react"
import { Badge, Box, Text } from "@chakra-ui/react"
import { DeleteIcon, EditIcon } from "@chakra-ui/icons"
import TooltipIconButton from "../../../components/IconActionButton/IconActionButton"
import { LocationInfo } from "../../../types/location-info"

interface UserRightsItemProps {
  item: LocationInfo
  onEdit: () => void
  onDelete: () => void
}
const LocationInfoItem: React.FC<UserRightsItemProps> = ({
  item,
  onEdit,
  onDelete,
}) => {
  return (
    <Box
      p={4}
      shadow="md"
      borderWidth="1px"
      my={2}
      borderRadius="md"
      position="relative"
      backgroundColor="white"
      _hover={{ backgroundColor: "yellow.100" }}
    >
      <Text as="b" fontSize="xl" mb={2} color="green.500">
        {item.type}
      </Text>
      <Text fontSize="md" mb={2} fontWeight="bold">
        Ставка: <Badge colorScheme="yellow">{item.rate}</Badge>
      </Text>
      <Text fontSize="md" mb={2} fontWeight="bold">
        Відсоток: <Badge colorScheme="yellow">{item.percentage}%</Badge>
      </Text>
      <TooltipIconButton
        label="Редагувати"
        icon={<EditIcon />}
        onClick={onEdit}
        sx={{ right: "50px", top: "10px" }}
      />
      <TooltipIconButton
        label="Видалити"
        icon={<DeleteIcon />}
        onClick={onDelete}
        sx={{ right: "10px", top: "10px" }}
      />
    </Box>
  )
}

export default LocationInfoItem
