import React, { FC } from "react"
import { Flex, Box, Divider } from "@chakra-ui/react"
import { Note } from "../../../types/note"
import InfoRow from "../../../components/InfoRow/InfoRow"
import {
  findSelectedShop,
  calculateSalaryPerDay,
  calculatePackagesPercent,
  calculateCup,
  calculatePercentageFromMargin,
} from "../../../utils"
import { Shop } from "../../../types/common"
import UniversalModal from "../../../components/UniversalModal/UniversalModal"
import { cfg } from "../../../cfg"

interface ViewModalProps {
  isViewModalOpen: boolean
  selectedNote: Note | undefined
  shopList: Shop[]
  handleCloseViewModal: () => void
}

const ViewModal: FC<ViewModalProps> = ({
  isViewModalOpen,
  selectedNote,
  shopList,
  handleCloseViewModal,
}) => {
  if (!selectedNote) return null

  const date =
    selectedNote.date && new Date(selectedNote?.date).toLocaleDateString()

  const selectedShop = findSelectedShop(
    shopList,
    selectedNote.shopid.toString(),
  )

  const selectedShopMarginPercentage = selectedShop?.percent || 0
  const selectedShopRate = selectedShop?.rate || 0

  const salaryPerDay = calculateSalaryPerDay(
    selectedShopMarginPercentage,
    selectedShopRate,
    selectedNote.margin,
  )

  const packagesPercent = calculatePackagesPercent({
    packages: selectedNote.packages.toString(),
    totalSeverityOfChecks: selectedNote.totalchecks.toString(),
  })

  const cup = calculateCup({
    checksToAnUnknownPerson: selectedNote.cup.toString(),
    totalSeverityOfChecks: selectedNote.totalchecks.toString(),
  })

  const percentageFromMargin = calculatePercentageFromMargin(
    selectedNote.margin,
    selectedShopMarginPercentage,
  )

  return (
    <UniversalModal
      isOpen={isViewModalOpen}
      onClose={handleCloseViewModal}
      title="Деталі запису"
    >
      <Flex direction="column" align="stretch" height="100%">
        <Box flex="5" overflow="auto">
          <InfoRow label={cfg.entryForm.data.label} value={date} />
          <InfoRow
            label={cfg.entryForm.shop.label}
            value={selectedNote.shopname}
          />
          <InfoRow
            label={cfg.entryForm.seller.label}
            value={selectedNote.responsiblename}
          />
          <InfoRow
            label={cfg.entryForm.getWithCard.label}
            value={selectedNote.cardreceipts}
          />
          <InfoRow
            label={cfg.entryForm.getInCash.label}
            value={selectedNote.cashreceipts}
          />
          <InfoRow
            label={cfg.entryForm.margin.label}
            value={selectedNote.margin}
          />
          <InfoRow
            label={cfg.entryForm.totalSeverityOfChecks.label}
            value={selectedNote.totalchecks}
          />
          <InfoRow
            label={cfg.entryForm.checksToAnUnknownPerson.label}
            value={selectedNote.cup}
          />
          <InfoRow
            label={cfg.entryForm.packages.label}
            value={selectedNote.packages}
          />
        </Box>

        <Divider borderWidth="2px" />

        <Box flex="5" overflow="auto">
          <InfoRow
            label={cfg.entryForm.labels.allIncome}
            value={`${selectedNote?.totalreceipts} грн`}
          />
          <InfoRow
            label={cfg.entryForm.labels.rate}
            value={`${selectedNote.rate} грн`}
          />
          <InfoRow
            label={cfg.entryForm.labels.percentFromMargin(
              selectedShopMarginPercentage,
            )}
            value={`${percentageFromMargin} грн`}
          />
          <InfoRow
            label={cfg.entryForm.labels.salaryPerDay}
            value={`${salaryPerDay} грн`}
          />
          <InfoRow
            label={cfg.entryForm.labels.unknownPercent}
            value={`${cup} %`}
          />
          <InfoRow
            label={cfg.entryForm.labels.packagesPercent}
            value={`${packagesPercent} %`}
          />
        </Box>
      </Flex>
    </UniversalModal>
  )
}

export default ViewModal
