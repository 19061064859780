import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/react"
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
} from "react-hook-form"

type Props<T extends Partial<FieldValues>> = {
  control: Control<T>
  name: Path<T>
  label?: string
  helperText?: string
  errors: any
  rules?: RegisterOptions
  placeholder?: string
  value?: string
}

export const AppInput = <T extends FieldValues>({
  control,
  name,
  label,
  helperText,
  errors,
  rules,
  placeholder,
  value,
}: Props<T>) => {
  return (
    <FormControl isInvalid={!!errors?.[name]}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => (
          <Input
            {...field}
            placeholder={placeholder}
            value={value || field.value}
          />
        )}
      />
      {!!errors?.[name] ? (
        <FormErrorMessage>{errors?.[name].message}</FormErrorMessage>
      ) : (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}
