import { createAsyncThunk } from "@reduxjs/toolkit"
import axiosInstance from "../../api/axiosConfig"
import { handleAsyncThunkError } from "../../utils"
import { LocationInfo, LocationInfoDtoIn } from "../../types/location-info"

export const fetchLocationInfoList = createAsyncThunk(
  "locationInfo",
  handleAsyncThunkError(async (args: void) => {
    const response = await axiosInstance.get(`location-info`)
    return response.data
  }),
)

export const createLocationInfo = createAsyncThunk(
  "locationInfo/create",
  handleAsyncThunkError(async (locationData: LocationInfoDtoIn) => {
    const response = await axiosInstance.post("location-info", locationData)
    return response.data
  }),
)

export const fetchLocationInfoById = createAsyncThunk(
  "locationInfo/fetchById",
  handleAsyncThunkError(async (id: number) => {
    const response = await axiosInstance.get(`location-info/?id=${id}`)
    return response.data
  }),
)

export const updateLocationInfo = createAsyncThunk(
  "locationInfo/update",
  handleAsyncThunkError(
    async ({
      id,
      locationData,
    }: {
      id: number
      locationData: Partial<LocationInfoDtoIn>
    }) => {
      const response = await axiosInstance.patch(
        `location-info/${id}`,
        locationData,
      )
      return response.data
    },
  ),
)

export const deleteLocationInfo = createAsyncThunk(
  "locationInfo/delete",
  handleAsyncThunkError(async (id: number) => {
    await axiosInstance.delete(`location-info/${id}`)
    return id
  }),
)
