import { createAsyncThunk } from "@reduxjs/toolkit"
import axiosInstance from "../../api/axiosConfig"
import { getUserFromBX24 } from "../../bx24"

export const fetchUserInfo = createAsyncThunk(
  "user/fetchUserInfo",
  async () => {
    let userId

    if (import.meta.env.MODE === "development") {
      userId = import.meta.env.VITE_DEFAULT_USER_ID
    } else {
      const bxUser = await getUserFromBX24()
      userId = bxUser?.id
    }

    if (!userId) {
      throw new Error("User ID is not available")
    }

    const response = await axiosInstance.post("worker-info", { userId })
    return response.data
  },
)
