import React, { useEffect, useState } from "react"
import { Box, Flex, Button, Tooltip } from "@chakra-ui/react"
import { useAppDispatch, useNoteList, useUserInfo } from "../app/hooks"
import { resetNoteSliceState } from "../features/note/noteSlice"
import ChartComponent from "../components/ChartComponent/ChartComponent"
import { DataItem } from "../types/seller-chart"
import { formatDateForChart } from "../utils"
import CustomSelect from "../components/CustomSelect/CustomSelect"
import { fetchNotes } from "../features/note/noteAPI"
import MonthYearPicker from "../components/MonthYearPicker/MonthYearPicker"
import { FcClearFilters } from "react-icons/fc"
import LoadingRow from "../components/LoadingRow/LoadingRow"
import AlertMessage from "../components/AlertMessage/AlertMessage"
import { Note } from "../types/note"

const SellerChartPage: React.FC = () => {
  const dispatch = useAppDispatch()

  const userInfo = useUserInfo()
  const noteList = useNoteList()

  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 1

  const [selectedSellerId, setSelectedSellerId] = useState<string | undefined>(
    undefined,
  )
  const [selectedMonth, setSelectedMonth] = useState<number | undefined>(
    currentMonth,
  )
  const [selectedYear, setSelectedYear] = useState<number | undefined>(
    currentYear,
  )

  const [isLoading, setIsLoading] = useState(false)
  const [isDataEmpty, setIsDataEmpty] = useState(false)

  const hasAllSelection = selectedSellerId && selectedMonth && selectedYear
  const hasOneSelection = Boolean(
    selectedSellerId || selectedMonth || selectedYear,
  )
  const shouldShowAlertInfo = !isLoading && !hasAllSelection
  const shouldShowAlertWarning = !isLoading && isDataEmpty && hasAllSelection

  useEffect(() => {
    dispatch(resetNoteSliceState())
    if (userInfo.user?.availableUserList?.length === 1) {
      setSelectedSellerId(userInfo.user.availableUserList[0].ID.toString())
    }
    if (selectedSellerId && selectedMonth && selectedYear) {
      setIsLoading(true)
      setIsDataEmpty(false)

      const fetchNotesDtoIn = {
        responsibleIdList: [selectedSellerId.toString()],
        selectedMonth: selectedMonth,
        selectedYear: selectedYear,
        pageInfo: {
          pageIndex: "0",
          pageSize: "1500",
        },
      }

      dispatch(fetchNotes(fetchNotesDtoIn))
        .unwrap()
        .then((notes) => {
          setIsDataEmpty(notes.noteList.length === 0)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [dispatch, selectedSellerId, selectedMonth, selectedYear])

  const calculateSalary = (item: Note) => {
    return parseFloat(
      (
        parseFloat(item.rate.toString()) +
        (parseFloat(item.margin) * parseFloat(item.percentage)) / 100
      ).toFixed(2),
    )
  }

  const preparedData: DataItem[] = noteList.map((item) => ({
    createdate: formatDateForChart(item.date),
    margin: parseFloat(item.margin.toString()),
    packages: parseFloat(item.packages.toString()),
    totalchecks: parseFloat(item.totalchecks.toString()),
    cup: parseFloat(item.cup.toString()),
    salary: calculateSalary(item),
  }))

  const sellerList = userInfo.user?.availableUserList || []

  const handleReset = () => {
    setSelectedSellerId(undefined)
    setSelectedMonth(undefined)
    setSelectedYear(undefined)
    setIsDataEmpty(false)
  }

  const renderAlertInfo = (
    <AlertMessage
      status="info"
      message="Будь ласка, оберіть продавця, рік та місяць для відображення даних."
    />
  )

  const renderAlertWarning = (
    <AlertMessage
      status="warning"
      message="Дані за вказаний період на обраного продавця відсутні. Будь ласка, спробуйте інший період або іншого продавця."
    />
  )

  const renderHeaderSection = (
    <Flex alignItems="center">
      <CustomSelect
        mr={2}
        w={200}
        placeholder="Вибрати продавця"
        onChange={(data) => setSelectedSellerId(data.value)}
        itemList={sellerList}
        value={selectedSellerId && parseFloat(selectedSellerId.toString())}
      />
      <MonthYearPicker
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
        onMonthChange={(month) => setSelectedMonth(parseFloat(month))}
        onYearChange={(year) => setSelectedYear(parseFloat(year))}
      />
      <Tooltip label="Скинути фільтра" hasArrow>
        <Button
          isDisabled={!hasOneSelection}
          leftIcon={<FcClearFilters />}
          colorScheme="red"
          variant="ghost"
          onClick={handleReset}
          ml={2}
        />
      </Tooltip>
    </Flex>
  )

  const renderChartList = (
    <>
      <ChartComponent
        data={preparedData}
        dataKey="margin"
        labelKey="Маржа"
        color="#8884d8"
        title="Маржа"
      />
      <ChartComponent
        data={preparedData}
        dataKey="salary"
        labelKey="Зарплата"
        color="#4cc0c0"
        title="Зарплата"
      />
      <ChartComponent
        data={preparedData}
        dataKey="packages"
        labelKey="Пакети"
        color="#82ca9d"
        title="Пакети"
      />
      <ChartComponent
        data={preparedData}
        dataKey="cup"
        labelKey="Чеки на невідомого"
        color="#ff7300"
        title="Чеки на невідомого"
      />
      <ChartComponent
        data={preparedData}
        dataKey="totalchecks"
        labelKey="Загальна кількість чеків"
        color="#b74cc0"
        title="Загальна кількість чеків"
      />
    </>
  )

  return (
    <Box p="5" my="4">
      <Flex justifyContent="space-between" alignItems="center" mb="4">
        {renderHeaderSection}
        {shouldShowAlertInfo && renderAlertInfo}
        {shouldShowAlertWarning && renderAlertWarning}
      </Flex>
      {isLoading && <LoadingRow />}
      {!isLoading && preparedData.length > 0 && renderChartList}
    </Box>
  )
}

export default SellerChartPage
