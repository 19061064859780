import { createSlice } from "@reduxjs/toolkit"
import { User } from "../../types/user"
import { fetchUserInfo } from "./userAPI"
import { LoadStatus, SliceErrorType } from "../../types/common"

export interface UserState {
  user: User | null
  status: LoadStatus
  error: SliceErrorType
}

const initialState: UserState = {
  user: null,
  status: LoadStatus.Idle,
  error: null,
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchUserInfo.pending, (state) => {
        state.status = LoadStatus.Loading
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.status = LoadStatus.Succeeded
        state.user = action.payload
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {
        state.status = LoadStatus.Failed
        state.error = action.error.message ? action.error.message : null
      })
  },
})

export default userSlice.reducer
