import React, { Fragment, useEffect } from "react"
import { useAppDispatch, useUserInfo } from "./app/hooks"
import { fetchUserInfo } from "./features/user/userAPI"
import { fetchNotes } from "./features/note/noteAPI"
import LoadingOverlay from "./components/LoadingModal/LoadingModal"
import { getSellerIds, getShopIds } from "./utils"
import { LoadStatus } from "./types/common"
import "toastr/build/toastr.min.css"
import { useSelector } from "react-redux"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import NotFound from "./components/NotFound/NotFound"
import NotesPage from "./pages/NotesPage"
import SettingsPage from "./pages/SettingsPage"
import CommonHeader from "./layout/CommonHeader"
import SettingsSellerAccess from "./pages/SettingsSellerAccess"
import { selectSelectionsDetails } from "./features/note/selectionSlice"
import SettingsSalaryRate from "./pages/SettingsSalaryRate"
import SellerChartPage from "./pages/SellerChartPage"

function App() {
  const dispatch = useAppDispatch()

  const userInfo = useUserInfo()
  const selections = useSelector(selectSelectionsDetails)

  const { selectedYear, selectedMonth } = selections

  const isUserLoading =
    userInfo.status === LoadStatus.Idle ||
    userInfo.status === LoadStatus.Loading

  useEffect(() => {
    dispatch(fetchUserInfo())
  }, [dispatch])

  useEffect(() => {
    if (userInfo.status === LoadStatus.Succeeded) {
      const initialShopIds = getShopIds(userInfo)
      const initialSellerIds = getSellerIds(userInfo)

      const fetchNotesDtoIn = {
        shopIdList: initialShopIds,
        responsibleIdList: initialSellerIds,
        selectedYear: selectedYear,
        selectedMonth: selectedMonth,
        pageInfo: { pageIndex: "0", pageSize: "20" },
      }

      dispatch(fetchNotes(fetchNotesDtoIn))
    }
  }, [userInfo.status, dispatch])

  const isAdmin = userInfo.user && userInfo.user.isAdmin

  const settingContent = (
    <Fragment>
      <CommonHeader title={"Налаштування"} />
      <SettingsPage />
    </Fragment>
  )

  const settingAccessContent = (
    <Fragment>
      <CommonHeader title={"Налаштування продавців"} />
      <SettingsSellerAccess />
    </Fragment>
  )

  const settingSalaryRateContent = (
    <Fragment>
      <CommonHeader title={"Налаштування ставки та відсотку"} />
      <SettingsSalaryRate />
    </Fragment>
  )

  const sellerChartContent = (
    <Fragment>
      <CommonHeader title={"Графіки"} />
      <SellerChartPage />
    </Fragment>
  )

  return (
    <Router>
      <Fragment>
        <Routes>
          <Route path="/" element={<NotesPage />} />
          {isAdmin && <Route path="/settings" element={settingContent} />}
          {isAdmin && (
            <Route
              path="/settings/user-access"
              element={settingAccessContent}
            />
          )}
          {isAdmin && (
            <Route
              path="/settings/user-salary-rate"
              element={settingSalaryRateContent}
            />
          )}
          <Route path="/seller-chart" element={sellerChartContent} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Fragment>
      <LoadingOverlay
        isOpen={isUserLoading || userInfo.status === LoadStatus.Failed}
        error={userInfo.error}
      />
    </Router>
  )
}

export default App
