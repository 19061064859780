import { Skeleton, Td, Tr } from "@chakra-ui/react"
import React from "react"

interface TableSkeletonProps {
  rows: number
  columns: number
}

export const TableSkeleton: React.FC<TableSkeletonProps> = ({
  rows,
  columns,
}) => {
  return (
    <>
      {Array.from({ length: rows }, (_, rowIndex) => (
        <Tr key={rowIndex}>
          {Array.from({ length: columns }, (_, colIndex) => (
            <Td py="2" px="3" key={colIndex}>
              <Skeleton height="32px" />
            </Td>
          ))}
        </Tr>
      ))}
    </>
  )
}
